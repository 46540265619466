import React from "react";

function Quotes_Svg() {
 return (
  <svg
   xmlns="http://www.w3.org/2000/svg"
   width="50"
   height="40"
   fill="none"
   viewBox="0 0 50 40"
  >
   <path
    fill="#AEAEAE"
    d="M40.614 20.425c5.607 0 9.386 3.839 9.386 9.526 0 5.118-4.343 9.382-10.225 9.382-6.443 0-11.204-5.118-11.204-12.935C28.571 8.628 41.736 1.52 50 .667v7.817c-5.604.995-11.904 6.539-12.186 12.655.282-.14 1.4-.714 2.8-.714zm-28.568 0c5.6 0 9.383 3.839 9.383 9.526 0 5.118-4.343 9.382-10.225 9.382C4.76 39.333 0 34.215 0 26.398 0 8.628 13.164 1.52 21.429.667v7.817C15.825 9.48 9.525 15.023 9.243 21.14c.282-.14 1.4-.714 2.803-.714z"
   ></path>
  </svg>
 );
}

export default Quotes_Svg;
