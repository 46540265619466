import { useEffect, useLayoutEffect,useState } from "react";
import App_Logo from "../../assets/svg/Application_Logo";
import Styles from "../css/Header_Component.module.css";
import {
 AiOutlineAlignLeft,
 AiOutlineBell,
 AiOutlineShoppingCart,
 AiOutlineCloseCircle,
} from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../storage/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { cartItemCount, tabId } from "../../redux/action";
import { BiLogIn } from "react-icons/bi";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod, PostMethodCart, GetMethodCartCourse } from "../../api/request/Api_Calls";
import RunTimeConstant from "../../config/RuntimeConfig";

const Header_Component = (props) => {
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const avatar_image = require("../../assets/image/avatar.png");
 const { token, cart_item_count, user_details, session_details, cartCourses } = useSelector(
  (state) => state.applicationState
 );

//  console.log("cartCourses ids ==== ", cartCourses)

 const [text_Link, set_Text_Link] = useState([
  {
   id: 0,
   name: "Home",
   route_name: "/",
  },
  {
   id: 1,
   name: "My Courses",
   route_name: "/myCourses",
  },
  ,
  // {
  //  id: 2,
  //  name: "Progress",
  //  route_name: "/progress",
  // },
  {
   id: 3,
   name: "All Courses",
   route_name: "/allCourse",
  },
 ]);

 const [icon_Link, set_Icon_Link] = useState([
  {
   id: 4,
   icon: <AiOutlineShoppingCart color="var(--primary-color)" size={20} />,
   route_name: "/cart",
  },
  // {
  //  id: 5,
  //  icon: <AiOutlineBell color="var(--primary-color)" size={20} />,
  //  route_name: "/notification",
  // },
  {
   id: 6,
   icon: <BiUserCircle color="var(--primary-color)" size={20} />,
   route_name: "/profile",
  },
 ]);

 const menu_Items = [
  {
   id: 0,
   name: "Home",
   route_name: "/",
  },
  {
   id: 1,
   name: "My Courses",
   route_name: "/myCourses",
  },
  ,
  // {
  //  id: 2,
  //  name: "Progress",
  //  route_name: "/progress",
  // },
  {
   id: 3,
   name: "All Courses",
   route_name: "/allCourse",
  },
  {
   id: 5,
   name: "Profile",
   route_name: "/profile",
  },
  // {
  //  id: 4,
  //  name: "Notifications",
  //  route_name: "/notification",
  // },
 ];

 //  console.log("user_details", user_details);
 const [tab_Index, set_Tab_Index] = useState(0);
 const [is_Small_Screen, set_Is_Small_Screen] = useState(false);
 const [openMenu, setOpenMenu] = useState(false);

 var menu_card_click = false;

 const handleResize = () => {
  if (window.innerWidth < 992) {
   set_Is_Small_Screen(true);
  } else {
   set_Is_Small_Screen(false);
  }
  // console.log("window.innerWidth", window.innerWidth)

  // Check for landscape orientation with a max-width of 767 pixels
  // const isLandscapeOrientation = window.matchMedia(
  //   "(max-height: 575.98px) and (orientation: landscape)"
  // ).matches;
  // setIsLandscape(isLandscapeOrientation);
 };

 //  const getProfileDetails = () => {
 //   let payload = {
 //    url: API_URL.usersAccount,
 //    data: {
 //     _id: token,
 //    },
 //   };
 //   PostMethod(payload)
 //    .then(async (response) => {
 //     console.log("getProfileDetails response in header", response);
 //     if (response.success) {
 //      const new_data = response?.data;
 //      if (new_data?.Image != "") {
 //       new_data.profileImage = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
 //        "PROFILE/" + new_data.Image
 //       )}`;
 //      } else {
 //       new_data.profileImage = avatar_image;
 //      }
 //      setProfileData(new_data);
 //     }
 //     // else {
 //     //  set_Is_Loading(false);
 //     //  toast.dismiss();
 //     //  toast(response.message);
 //     // }
 //    })
 //    .catch((error) => {
 //     console.log("getProfileDetails error", error);
 //     // set_Is_Loading(false);
 //     // set_Is_Network_Error(true);
 //    });
 //  };

 useEffect(() => {
  handleResize();
  if (openMenu) {
   document.body.classList.add(Styles.body_no_scroll);
  } else {
   document.body.classList.remove(Styles.body_no_scroll);
  }
  window.addEventListener("resize", handleResize);
  return () => {
   window.removeEventListener("resize", handleResize);
   document.body.classList.remove(Styles.body_no_scroll);
  };
 }, [openMenu]);

 // useEffect(()=>{
 //     console.log("ChangeTab")
 //     ChangeTab()
 // }, [tab_Index])

 useEffect(() => {
  // console.log("ChangeTab")
  ChangeTab();
  // getProfileDetails();
 });

 /* integrate new get cart api in here */
 const getCartData = () => {
  let payload = {
   url: API_URL.get_from_cart,
   data: {
    UserID: token,
   },
  };
  GetMethodCartCourse(payload)
   .then(async (response) => {
    console.log("getCartData response outside cart", response);
    if (response.status === 200) {
     let new_data = response?.data.data;
     console.log("new_data outside cart ======== ", new_data)
     dispatch(cartItemCount({ cart_item_count: new_data.length }));
    //  dispatch(cartItemCount({ cart_item_count: cart_item_count + 1 }));
    }
   })
   .catch((error) => {
    console.log("getCartData error", error);
   });
 };

/*  useEffect(() => {
  if (token) {
   getCartData();
  }
 }, [token]); */

  useLayoutEffect(() => {
    if (token) {
      getCartData();
     }
 }, [token]);

 const ChangeTab = async () => {
  let tab_Id = await getLocalStorage("Tab_ID");
  // console.log("set_Tab_Index", tab_Index, tab_Id)
  set_Tab_Index(tab_Id);
 };

 // const tabClick = async(ele) => {
 //     await dispatch(tabId({tab_Id: ele?.id}))
 //     set_Tab_Index(ele?.id)
 //     navigate(ele?.route_name)
 // }
 const header_Nav = async (ele) => {
  dispatch(tabId({ tab_Id: ele?.id }));
  set_Tab_Index(ele?.id);
  navigate(ele.route_name);
 };

 const closeMenu = () => {
  console.log(" over all");
  if (!menu_card_click) {
   if (openMenu) {
    setOpenMenu(false);
   }
  } else {
   menu_card_click = false;
  }
 };

 const menuItemClick = (route) => {
  navigate(route);
  setOpenMenu(false);
 };

 const menuCardClick = () => {
  menu_card_click = true;
 };

 return (
  <>
   {!is_Small_Screen ? (
    <div className={Styles.header_Container}>
     <div className={Styles.header_body}>
      <div
       className={Styles.app_Logo_View}
       tabIndex={0}
       onKeyDown={(event) => {
        if (event.key == "Enter") {
         navigate("/");
        }
       }}
       onClick={() => navigate("/")}
      >
       <div>
        <App_Logo />
       </div>
       <div className={Styles.app_Name_View}>CognitivED</div>
      </div>

      {token != null ? (
       <div className={Styles.assests_View}>
        {text_Link.map((ele) => {
         return (
          <div
           key={ele.id} // Use ele.id instead of ind
           className={
            tab_Index == ele.id ? Styles.text_Link_Seleted_View : Styles.text_Link_View
           }
           tabIndex={0}
           onClick={() => header_Nav(ele)}
           onKeyDown={(event) => {
            if (event.key == "Enter") {
             header_Nav(ele);
            }
           }}
          >
           {ele?.name}
          </div>
         );
        })}

        {icon_Link.map((ele) => {
         const isShoppingCart = ele.id === 4;
         return (
          <div key={ele.id} style={{ paddingLeft: "5px", paddingRight: "5px", display: "flex", alignItems:"center", justifyContent:"center"}}>
           {" "}
           {/* Use ele.id instead of ind */}
           {isShoppingCart && cart_item_count >= 1 && (
            <div className={Styles.isShoppingCart}>
             <div className={Styles.isShoppingCartCount}>{cart_item_count}</div>
            </div>
           )}
           <div
            className={
             tab_Index === ele.id ? Styles.icon_Link_Slected_View : Styles.icon_Link_View
            }
            tabIndex={0}
            onClick={() => header_Nav(ele)}
            onKeyDown={(event) => {
             if (event.key == "Enter") {
              header_Nav(ele);
             }
            }}
           >
            {ele?.icon}
           </div>
          </div>
         );
        })}
       </div>
      ) : (
       <>
        {props.element == "Show" && (
         <div className={Styles.assests_View}>
          <div
           className={Styles.text_Icon_View}
           tabIndex={0}
           onClick={() => navigate("/registration")}
           onKeyDown={(event) => {
            console.log("e", event.key);
            if (event.key == "Enter") {
             navigate("/registration");
            }
           }}
          >
           <div className={Styles.signUp_Text}>Sign up</div>
           <BiLogIn size={25} color={"var(--primary-color)"} />
          </div>
          <div
           className={Styles.button_View}
           tabIndex={0}
           onClick={() => navigate("/login")}
           onKeyDown={(event) => {
            console.log("e", event.key);
            if (event.key == "Enter") {
             navigate("/login");
            }
           }}
          >
           <div className={Styles.login_Text}>Sign in</div>
          </div>
         </div>
        )}
       </>
      )}
     </div>
    </div>
   ) : (
    <div>
     {openMenu && (
      <div className={Styles.side_MenuContainer_View} onClick={() => closeMenu()}>
       <div className={Styles.sideMenu_Container} onClick={() => menuCardClick()}>
        <div className={Styles.profile_card_View}>
         <div className={Styles.profile_Name_View}>
          <div className={Styles.avatar_Image_View}>
           <img
            src={user_details?.profileImage}
            className={Styles.avatar_Image_Fit}
            crossOrigin="anonymous"
           />
          </div>

          <div className={Styles.userName_View}>
           <div className={Styles.userName_Text}>{user_details?.UserName}</div>
           <div className={Styles.email_Text}>{user_details?.EmailId}</div>
          </div>
         </div>

         <div className={Styles.close_Icon_View} onClick={() => setOpenMenu(false)}>
          <AiOutlineCloseCircle color="var(--primary-color)" size={25} />
         </div>
        </div>

        <div>
         {menu_Items.map((ele) => {
          return (
           <div
            key={ele.id} // Use ele.id here as well
            className={Styles.menu_Items_View}
            onClick={() => menuItemClick(ele.route_name)}
           >
            {ele.name}
           </div>
          );
         })}
        </div>
       </div>
      </div>
     )}
     <div
      className={Styles.menuContainer}
      style={{ justifyContent: token != null ? "space-between" : "center" }}
     >
      {token != null && (
       <div onClick={() => setOpenMenu(true)}>
        <AiOutlineAlignLeft size={35} color={"var(--text-color)"} />
       </div>
      )}

      <div onClick={() => navigate("/")} className={Styles.header_Name_View}>
       <div>
        <App_Logo />
       </div>
       <div className={Styles.header_Name}>CognitivED</div>
      </div>

      {token != null && (
       <div className={Styles.isShoppingCartMobile} onClick={() => navigate("/cart")}>
        <AiOutlineShoppingCart size={35} color={"var(--primary-color)"} />

        {cart_item_count >= 1 && (
         <div className={Styles.isShoppingCartCountMobile}>{cart_item_count}</div>
        )}
       </div>
      )}
     </div>
    </div>
   )}
  </>
 );
};

export default Header_Component;
