import React, { Component } from "react";

class WS extends Component {
 constructor(props) {
  super(props);
  this.state = {
   ws: null,
  };
  this.reconnect = !!this.props.reconnect;
  this.wsRef = React.createRef();
 }

 componentDidMount() {
  this._handleWebSocketSetup();
 }

 componentWillUnmount() {
  this.reconnect = false;
  if (this.wsRef.current) {
   this.wsRef.current.close();
  }
 }

 // WebSocket setup logic
 _handleWebSocketSetup = () => {
  const ws = new WebSocket(this.props.url);
  this.wsRef.current = ws;

  ws.onopen = () => {
   this.props.onOpen && this.props.onOpen();
   //    console.log("WebSocket connected");
  };

  ws.onmessage = (event) => {
   this.props.onMessage && this.props.onMessage(event);
   //    console.log("Message from server:", event.data);
  };

  ws.onerror = (error) => {
   this.props.onError && this.props.onError(error);
   //    console.log("WebSocket error:", error);
  };

  ws.onclose = () => {
   this.props.onClose && this.props.onClose();
   //    console.log("WebSocket connection closed");

   if (this.reconnect) {
    // setTimeout(() => {
    //  console.log("Reconnecting WebSocket...");
    //  this._handleWebSocketSetup();
    // }, 10000); // Reconnect after 10 seconds
   }
  };

  this.setState({ ws });
 };

 send = (message) => {
  if (this.wsRef.current && this.wsRef.current.readyState === WebSocket.OPEN) {
   this.wsRef.current.send(message);
  } else {
   console.log("WebSocket is not open. Cannot send message.");
  }
 };

 render() {
  return null; // This component doesn't render anything, just handles WebSocket
 }
}

export default WS;
