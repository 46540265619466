import "./App.css";
import { getSessionMethod } from "../src/api/request/Api_Calls";
import { API_URL } from "../src/api/URL/Api_Route";
import { useDispatch, useSelector } from "react-redux";
import { sessionDetails, LogIn } from "./redux/action";
import { Toaster } from "react-hot-toast";
import { Router_Provider } from "./router_container/routers";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

function App() {
 const [isRender, setRender] = useState(false);
 const { token } = useSelector((state) => state.applicationState);
 const dispatch = useDispatch();

 useEffect(() => {
  initialFetchToken();
 }, []);

 const initialFetchToken = async () => {
  let payload = {
   url: `${API_URL.getSession}`, // /cache/get_session_details
  };
  getSessionMethod(payload)
   .then(async (res) => {
    console.log("get_session_details response === ", res.data);
    if (res?.status == 200 && res.data?.status) {
     dispatch(sessionDetails({ session_details: res.data })); // includes country code along with other session details
     dispatch(LogIn({ token: res.data.sub })); //sub uuid
    } else if (res?.status == 409) {
     console.log("get_session_details failure res === ", res);
    }
   })
   .catch((err) => {
    if (err?.response?.status == 404) {
     console.log("get_session_details failure");
    } else {
     console.log("get_session_details catch error === ", err);
    }
   });

  setRender(true);
 };

 return (
  <div className="App">
   <Toaster />
   {isRender && <Router_Provider token={token} />}
  </div>
 );
}

export default App;
