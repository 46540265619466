const RunTimeConstant = {
 BASEURL: "",
 SERVICEURL: {
  AMS: "", // Account Management Service
  IMS: "", // Identity Management Service
  PMS: "", // Policy Management Service
  CMS: "", // Cache Management Service
  MS: "", // Messaging Service
  CS: "", // Course Service
  PAYMS: "", // Payment Service
  PRSMS: "", // Purchase Management Service
  TMS: "", // TAX Management Service
 },
};

if (process.env.NODE_ENV == "development") {
 /* local server */
 //  RunTimeConstant.BASEURL = "http://127.0.0.1:55548/";
//  RunTimeConstant.SERVICEURL.AMS = "http://127.0.0.1:55598/";
//  RunTimeConstant.SERVICEURL.IMS = "http://127.0.0.1:55601/";
//  RunTimeConstant.SERVICEURL.PMS = "http://127.0.0.1:55603/";
//  RunTimeConstant.SERVICEURL.CMS = "http://127.0.0.1:55599/";
// //  RunTimeConstant.SERVICEURL.CS = "http://127.0.0.1:61111/";
//  RunTimeConstant.SERVICEURL.CS = "http://127.0.0.1:4041/";
//  RunTimeConstant.SERVICEURL.PAYMS = "http://127.0.0.1:51111/";
// //  RunTimeConstant.SERVICEURL.MS = "http://192.168.10.155:3001/"; // need to be config */
//  RunTimeConstant.SERVICEURL.MS = "http://127.0.0.1:3001/"; // need to be config */
//  RunTimeConstant.SERVICEURL.PRSMS = "http://127.0.0.1:31311/"; // need to be config */
//  RunTimeConstant.SERVICEURL.TMS = "http://127.0.0.1:32321/"; // need to be config */

 /* development server */
 //   RunTimeConstant.BASEURL = "http://192.168.10.34:55548/";
 //   RunTimeConstant.SERVICEURL.AMS = "https://devsvr.iinvsys.com:86/CEDUA/";
 //   RunTimeConstant.SERVICEURL.IMS = "https://devsvr.iinvsys.com:86/CEDUIdenty/";
 //   RunTimeConstant.SERVICEURL.CMS = "https://devsvr.iinvsys.com:86/CEDUC/";
 //   RunTimeConstant.SERVICEURL.PMS = "https://devsvr.iinvsys.com:86/CEDUPolicy/";

 /* staging server */

 /* login and register */
  // RunTimeConstant.SERVICEURL.AMS = "https://ftp.iinvsys.com:55576/CEDUA/";
  // RunTimeConstant.SERVICEURL.IMS = "https://ftp.iinvsys.com:55576/CEDUIdenty/";
  // RunTimeConstant.SERVICEURL.PMS = "https://ftp.iinvsys.com:55576/CEDUPolicy/";
  // RunTimeConstant.SERVICEURL.CMS = "https://ftp.iinvsys.com:55576/CEDUC/";

  // RunTimeConstant.SERVICEURL.CS = "https://ftp.iinvsys.com:55576/CEDUCO/";
  // RunTimeConstant.SERVICEURL.PAYMS = "https://ftp.iinvsys.com:55576/CEDUPayService/";
  // RunTimeConstant.SERVICEURL.MS = "https://ftp.iinvsys.com:55576/CEDUMsgService/";
  // RunTimeConstant.SERVICEURL.PRSMS = "https://ftp.iinvsys.com:55576/CEDUPurchaseService/";
  // RunTimeConstant.SERVICEURL.TMS = "https://ftp.iinvsys.com:55576/CEDUTaxService/"

  RunTimeConstant.SERVICEURL.AMS = "https://staging.cognitive-edu.com:55621/CEDUA/";
  RunTimeConstant.SERVICEURL.IMS = "https://staging.cognitive-edu.com:55621/CEDUIdenty/";
  RunTimeConstant.SERVICEURL.PMS = "https://staging.cognitive-edu.com:55621/CEDUPolicy/";
  RunTimeConstant.SERVICEURL.CMS = "https://staging.cognitive-edu.com:55621/CEDUC/";
  
  RunTimeConstant.SERVICEURL.CS = "https://staging.cognitive-edu.com:55621/CEDUCO/";
  RunTimeConstant.SERVICEURL.PAYMS = "https://staging.cognitive-edu.com:55621/CEDUPayService/";
  RunTimeConstant.SERVICEURL.MS = "https://staging.cognitive-edu.com:55621/CEDUMsgService/";
  RunTimeConstant.SERVICEURL.PRSMS = "https://staging.cognitive-edu.com:55621/CEDUPurchaseService/";
  RunTimeConstant.SERVICEURL.TMS = "https://staging.cognitive-edu.com:55621/CEDUTaxService/"
//  RunTimeConstant.SERVICEURL.AMS = "http://127.0.0.1:55598/";
//  RunTimeConstant.SERVICEURL.IMS = "http://127.0.0.1:55601/";
//  RunTimeConstant.SERVICEURL.PMS = "http://127.0.0.1:55603/";
//  RunTimeConstant.SERVICEURL.CMS = "http://127.0.0.1:55599/";
//  RunTimeConstant.SERVICEURL.MS = "http://192.168.10.195:3001/"; // need to be config */
 //  CMS_PORT: '61111',
 //  CEDU_PAYMS: '51111',
 //  AMS_PORT: '55598',
 //  CS_PORT: '55599',
 //  IMS_PORT: '55601',
 //  PLMS_PORT: '55602',
 //  PMS_PORT: '55603',
 //  RMS_PORT: '55604',
 //  CES_PORT: '55600',
 //change
 // RunTimeConstant.BASEURL = "https://ftp.iinvsys.com:55576/slu/";
} else if (process.env.NODE_ENV == "production") {
 //URL
//  RunTimeConstant.BASEURL = "https://sslluu.onrender.com/";
RunTimeConstant.SERVICEURL.AMS = "https://staging.cognitive-edu.com:55621/CEDUA/";
RunTimeConstant.SERVICEURL.IMS = "https://staging.cognitive-edu.com:55621/CEDUIdenty/";
RunTimeConstant.SERVICEURL.PMS = "https://staging.cognitive-edu.com:55621/CEDUPolicy/";
RunTimeConstant.SERVICEURL.CMS = "https://staging.cognitive-edu.com:55621/CEDUC/";

RunTimeConstant.SERVICEURL.CS = "https://staging.cognitive-edu.com:55621/CEDUCO/";
RunTimeConstant.SERVICEURL.PAYMS = "https://staging.cognitive-edu.com:55621/CEDUPayService/";
RunTimeConstant.SERVICEURL.MS = "https://staging.cognitive-edu.com:55621/CEDUMsgService/";
RunTimeConstant.SERVICEURL.PRSMS = "https://staging.cognitive-edu.com:55621/CEDUPurchaseService/";
RunTimeConstant.SERVICEURL.TMS = "https://staging.cognitive-edu.com:55621/CEDUTaxService/"
} else {
 //URL
 RunTimeConstant.BASEURL = "http://192.168.10.34:55548/";
 RunTimeConstant.SERVICEURL.AMS = "https://staging.cognitive-edu.com:55621/CEDUA/";
 RunTimeConstant.SERVICEURL.IMS = "https://staging.cognitive-edu.com:55621/CEDUIdenty/";
 RunTimeConstant.SERVICEURL.PMS = "https://192.168.10.33:40005/";
 RunTimeConstant.SERVICEURL.CMS = "https://staging.cognitive-edu.com:55621/CEDUC/";
}
export default RunTimeConstant;
