import React, { useEffect, useRef, useState } from "react";
import Styles from "../css/video.module.css";
import sampleVideo from "../../assets/video/sample_video.mp4";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { HiOutlineSpeakerWave } from "react-icons/hi2";
import { TbArrowBackUp, TbArrowForward } from "react-icons/tb";
import { TbArrowForwardUp } from "react-icons/tb";
import { VscScreenFull } from "react-icons/vsc";
import { MdOutlineSubtitles } from "react-icons/md";
import { GoMute } from "react-icons/go";
import backward from "../../assets/image/backward.png";
import forward from "../../assets/image/forward.png";
import subtitles from "../../assets/image/subtitles.png";
import fullscreen from "../../assets/image/fullscreen.png";
import { MdWarning } from 'react-icons/md';
import SessionPopUp from "../../component/javascript/SessionPopUp";

const VideoPlayer = (props) => {
 const bufferRef = useRef();
 const controlsRef = useRef();
 const progressRef = useRef();
 const soundbarRef = useRef(null);
 const [isPlaying, setIsPlaying] = useState(false);
 const [volume, setVolume] = useState(1);
 const [isWaiting, setIsWaiting] = useState(false);
 const [totalDuration, setTotalDuration] = useState(0);
 const [elapseTime, setElapseTime] = useState(0);
 const [playbackSpeed, setPlaybackSpeed] = useState(1);
 const [toggleVolume, setToggleVolume] = useState(false);
 const [subtitlesEnabled, setSubtitlesEnabled] = useState(false);
 const [showText, setShowText] = useState(false);
 const [isForward, setIsForward] = useState(true);
 const [isHovered, setIsHovered] = useState(false);
 const [sessionPopUp, setsessionPopUp] = useState(false);
 const [previousVolume, setPreviousVolume] = useState(null);
 const [videoUrl, setVideoUrl] = useState(props?.url);
 const volumeControlRef = useRef(null);
 const videoRef = useRef(null);
 const [isMobileView, setIsMobileView] = useState(false);
 const [showMessage,setMessage] = useState(false);

 React.useEffect(() => {
  const handleResize = () => {
   setIsMobileView(window.innerWidth <= 768); 
  };
  handleResize();
  window.addEventListener("resize", handleResize);

  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);
 

 useEffect(() => {
  if (!videoRef.current) return;
  const element = videoRef.current;
  const onPlay = () => {
   if (isWaiting) setIsWaiting(false);
   setIsPlaying(true);
  };
  const onPause = () => {
   if (isWaiting) setIsWaiting(false);
   setIsPlaying(!setIsPlaying);
  };
  const onWaiting = () => {
   if (isPlaying) setIsPlaying(false);
   setIsWaiting(false);
  };

  const onTimeUpdate = () => {
   setIsWaiting(false);
   if (!progressRef.current) return;
   const { currentTime, duration } = element;
   setTotalDuration(isNaN(duration) ? 0 : duration);
   setElapseTime(isNaN(currentTime) ? 0 : currentTime);
   const progress = currentTime / duration;
   const width = progress * 100;
   progressRef.current.style.width = `${width}%`;
   if (currentTime == duration) {
    props?.updateVideoCompletedStatus();
   }
  };

  const onProgress = () => {
   if (!element.buffered.length || !bufferRef.current) return;
   const { duration } = element;
   const bufferedEnd = element.buffered.end(element.buffered.length - 1);
   if (bufferRef.current && duration > 0) {
    const width = (bufferedEnd / duration) * 100;
    bufferRef.current.style.width = `${width}%`;
   }
  };

  const onLoadedMetadata = () => {
   const duration = element.duration;
   setTotalDuration(isNaN(duration) ? 0 : duration);
  };
  element.addEventListener("play", onPlay);
  element.addEventListener("playing", onPlay);
  element.addEventListener("pause", onPause);
  element.addEventListener("waiting", onWaiting);
  element.addEventListener("timeupdate", onTimeUpdate);
  element.addEventListener("progress", onProgress);
  element.addEventListener("loadedmetadata", onLoadedMetadata);

  // Pause the video when the component mounts
  element.pause();

  return () => {
   element.removeEventListener("play", onPlay);
   element.removeEventListener("playing", onPlay);
   element.removeEventListener("pause", onPause);
   element.removeEventListener("waiting", onWaiting);
   element.removeEventListener("timeupdate", onTimeUpdate);
   element.removeEventListener("progress", onProgress);
   element.removeEventListener("loadedmetadata", onLoadedMetadata);
  };
 }, [videoRef.current]);

 const handlePlay = () => {
  if (isPlaying) {
    console.log("Hello1 bb")
   videoRef.current.pause();
  } else {
    console.log("Hello2 bb")
   videoRef.current.play();
  }
  console.log("Hello3 bb")
  setIsPlaying(!isPlaying);
 };

 const handleToggleSoundBar = () => {
  setIsHovered((prev) => !prev);
 };

 const seekToPosition = (e) => {
  if (!videoRef.current) return;
  const { left, width } = e.currentTarget.getBoundingClientRect();
  const clickPos = (e.clientX - left) / width;
  if (clickPos < 0 || clickPos > 1) return;
  const durationMs = videoRef.current.duration * 1000;
  const newElapseTimeMs = durationMs * clickPos;
  const newTimeSec = newElapseTimeMs / 1000;
  videoRef.current.currentTime = newTimeSec;
 };

 const handleFastForward = () => {
  if (videoRef.current.currentTime + 10 < totalDuration) {
   videoRef.current.currentTime += 10;
   setShowText(true);
   setIsForward(true);
   setTimeout(() => {
    setShowText(false);
   }, 300);
  }
 };

 const handleFastBackward = () => {
  videoRef.current.currentTime -= 10; // Adjust the time to fast backward (e.g., 10 seconds)
  setShowText(true);
  setIsForward(false);
  setTimeout(() => {
   setShowText(false);
  }, 300);
 };

 const handlePlaybackSpeedChange = (e) => {
  const speed = parseFloat(e.target.value);
  setPlaybackSpeed(speed);
  videoRef.current.playbackRate = speed;
 };

 const toggleVolumeBar = () => {
  setToggleVolume(!toggleVolume);
  const newVolume = volume === 0 ? 0.5 : 0;
  setVolume(newVolume);
  videoRef.current.volume = newVolume; // Set the volume of the video element
  if (volume === 0) {
   // If volume is currently muted, restore previous volume
   const newVolume = previousVolume !== null ? previousVolume : 0.5; // If previousVolume is not set, set it to 0.5
   setVolume(newVolume);
   // Set the volume of the video element
   // videoRef.current.volume = newVolume;
   setPreviousVolume(null); // Reset previousVolume
  } else {
   // If volume is not muted, mute it and store current volume
   setPreviousVolume(volume);
   setVolume(0);
   // Set the volume of the video element
   // videoRef.current.volume = 0;
  }
 };

 const handleVolumeChange = (e) => {
  const newVolume = parseFloat(e.target.value);
  setVolume(newVolume);
  videoRef.current.volume = newVolume; // Set the volume of the video element
 };

 useEffect(() => {
  // Function to handle clicks outside the soundbar container
  // const handleClickOutside = (event) => {
  //   if (soundbarRef.current && !soundbarRef.current.contains(event.target)) {
  //     setToggleVolume(false); // Close the volume bar
  //   }
  // };
  const handleFullScreenChange = () => {
   if (!document.fullscreenElement && controlsRef.current) {
    // If exiting fullscreen and controlsRef is defined, show controls
    controlsRef.current.style.display = "block";
   }
  };

  document.addEventListener("fullscreenchange", handleFullScreenChange);

  // Attach the event listener when the component mounts
  // document.addEventListener("click", handleClickOutside);

  // Cleanup the event listener when the component unmounts
  return () => {
   // document.removeEventListener("click", handleClickOutside);
   document.removeEventListener("fullscreenchange", handleFullScreenChange);
  };
 }, []);

 const handleFullScreen = () => {
  if (videoRef.current.requestFullscreen) {
   videoRef.current.requestFullscreen().catch((err) => {
    // console.error("Failed to enable fullscreen mode:", err);
   });
  } else if (videoRef.current.mozRequestFullScreen) {
   /* Firefox */
   videoRef.current.mozRequestFullScreen();
  } else if (videoRef.current.webkitRequestFullscreen) {
   /* Chrome, Safari & Opera */
   videoRef.current.webkitRequestFullscreen();
  } else if (videoRef.current.msRequestFullscreen) {
   /* IE/Edge */
   videoRef.current.msRequestFullscreen();
  }

  // Hide controls when entering fullscreen
  if (controlsRef.current) {
   controlsRef.current.style.display = "none";
  }
 };


 const handleClickOutside = (event) => {
  if (volumeControlRef.current && !volumeControlRef.current.contains(event.target)) {
   setIsHovered(false);
  }
 };

 useEffect(() => {
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
   document.removeEventListener("mousedown", handleClickOutside);
  };
 }, []);

 
 const handleError = (e) => {
   try{
     const videoElement = e.target;
     console.log("videoElement   =====>",videoElement)
     const error = videoElement.error.code;
     const err = videoElement.error
     console.log("videoElement error code =====>",err)
     if(error === 2){
      setMessage(true)
      setsessionPopUp(true); 
     }
   }
   catch(e){
     console.log("e  ======>",e)
   }
 }

 
//  const videoKey = encodeURIComponent('COURSE/CRS1731319392240/VID1731321162567')
console.log("video_url ====>>>> ", props?.url)
 const videoKey = encodeURIComponent(props?.url)

// const url  = `http://127.0.0.1:4041/assert/video_player/${videoKey}`
const url  = `https://ftp.iinvsys.com:55576/CEDUCO/assert/video_player/${videoKey}`

// console.log("videoKey === ", videoKey)

 console.log('video fetching url =====>',url)

 return (
  <>
  {sessionPopUp && <SessionPopUp />}
  <div className={Styles.video_container}>
   <div className={Styles.video_player} onClick={handlePlay}>
    <video 
      width="100%" 
      height="100%" 
      style={{ zIndex: '1' }} 
      ref={videoRef}
      src={url}
      crossOrigin="use-credentials" 
      onError={handleError}
      autoPlay={true}
      muted={true}
    >
    </video>
    <div className={Styles.playIcon}>
     {isPlaying ? <FaPause size={25} color="#fff" /> : <FaPlay size={25} color="#fff" />}
    </div>
    {showText && (
     <div className={Styles.fast_text_container}>
      {isForward ? (
       <div className={Styles.fast_text_right}>10 secs</div>
      ) : (
       <div className={Styles.fast_text_left}>-10 secs</div>
      )}
     </div>
    )}

   {
      showMessage &&
      <div 
        style={{
          backgroundColor: 'rgba(0,0,0)', 
          position: 'absolute', 
          top: '0', 
          left: '0', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          width: '100%', 
          height: '100%', 
          color: 'white', 
          zIndex: '10' , 
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          flexDirection:'column',
          gap:'30px'
        }}
      >
       <MdWarning style={{height:'100px' , width:'100px', size:'50px',color:'red'}}/> 
        <p>Could Not Load Video</p>
      </div>
    }
   </div>
   <div className={Styles.seekToPositionView}>
    <div className={Styles.video_timeline} onClick={seekToPosition}>
     <div className={Styles.buffer_progress} ref={bufferRef} />
     <div className={Styles.play_progress} ref={progressRef}>
      <div className={Styles.end_circle} />
     </div>
    </div>
   </div>
   {/* Mobile View */}
   <div className={Styles.mobile_video_player}>
    <div className={Styles.mobile_video_player_cont}>
     {isPlaying ? (
      <FaPause
       size={15}
       color="#000"
       onClick={handlePlay}
       className={Styles.videoActive}
      />
     ) : (
      <FaPlay
       size={15}
       color="#000"
       onClick={handlePlay}
       className={Styles.videoActive}
      />
     )}
     <img
      height={11}
      width={12}
      src={backward}
      className={Styles.video_backward}
      onClick={handleFastBackward}
     />
     <select
      className={Styles.playback_speed}
      value={playbackSpeed}
      onChange={handlePlaybackSpeedChange}
     >
      <option value={0.5}>0.5x</option>
      <option value={0.75}>0.75x</option>
      <option value={1}>1x</option>
      <option value={1.25}>1.25x</option>
      <option value={1.5}>1.5x</option>
      <option value={1.75}>1.75x</option>
      <option value={2}>2x</option>
     </select>
     <img
      height={11}
      width={12}
      src={forward}
      onClick={handleFastForward}
      className={Styles.video_forward}
     />
     <ElapseTimeTrack elapseTime={elapseTime} duration={totalDuration} />
    </div>
    <div className={Styles.mobile_video_controls}>
     <div
      ref={volumeControlRef}
      style={{ position: "relative" }}
      onMouseEnter={() => !isMobileView && setIsHovered(true)}
      onMouseLeave={() => !isMobileView && setIsHovered(false)}
      onClick={isMobileView ? handleToggleSoundBar : null}
     >
      <div
       className={Styles.soundBar_mobile}
       style={{ display: isHovered ? "block" : "none" }}
      >
       <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={handleVolumeChange}
        className={Styles.volume_slider_vertical}
       />
      </div>
      {volume > 0 ? (
       <HiOutlineSpeakerWave
        className={Styles.volumes}
        size={28}
        onClick={toggleVolumeBar}
        // tabIndex={0}
        // onKeyDown={(event) => {
        //   if (event.key == "Enter") {
        //     toggleVolumeBar();
        //   }
        // }}
       />
      ) : (
       <GoMute
        size={28}
        className={Styles.volumes}
        onClick={toggleVolumeBar}
        // tabIndex={0}
        // onKeyDown={(event) => {
        //   if (event.key == "Enter") {
        //     toggleVolumeBar();
        //   }
        // }}
       />
      )}
     </div>
     {/* <MdOutlineSubtitles
            size={25}
            className={Styles.subtitles}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key == "Enter") {
                return;
              }
            }}
          /> */}
     {/* <VscScreenFull
            size={25}
            className={Styles.fullScreen}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key == "Enter") {
                handleFullScreen();
              }
            }}
            onClick={handleFullScreen}
          /> */}
     <img
      height={24}
      width={28}
      src={subtitles}
      className={Styles.subtitles}
      // tabIndex={0}
      // onKeyDown={(event) => {
      //   if (event.key == "Enter") {
      //     return;
      //   }
      // }}
     />
     <img
      height={24}
      width={28}
      src={fullscreen}
      className={Styles.fullScreen}
      // tabIndex={0}
      // onKeyDown={(event) => {
      //   if (event.key == "Enter") {
      //     handleFullScreen();
      //   }
      // }}
      onClick={handleFullScreen}
     />
    </div>
   </div>
   <div className={Styles.video_controls}>
    <div className={Styles.controls_one}>
     {isPlaying ? (
      <FaPause
       size={20}
       color="#000"
       // tabIndex={0}
       // onKeyDown={(event) => {
       //   if (event.key == "Enter") {
       //     handlePlay();
       //   }
       // }}
       onClick={handlePlay}
       className={Styles.videoActive}
      />
     ) : (
      <FaPlay
       size={20}
       color="#000"
       // tabIndex={0}
       // onKeyDown={(event) => {
       //   if (event.key == "Enter") {
       //     handlePlay();
       //   }
       // }}
       onClick={handlePlay}
       className={Styles.videoActive}
      />
     )}
     {/* <TbArrowBackUp
            size={20}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key == "Enter") {
                handleFastBackward();
              }
            }}
            onClick={handleFastBackward}
            className={Styles.video_backward}
          /> */}
     <img
      height={20}
      width={20}
      src={backward}
      className={Styles.video_backward}
      // tabIndex={0}
      // onKeyDown={(event) => {
      //   if (event.key == "Enter") {
      //     handleFastBackward();
      //   }
      // }}
      onClick={handleFastBackward}
     />
     {/* <div className={Styles.playback_speed}>1x</div> */}
     <select
      className={Styles.playback_speed}
      value={playbackSpeed}
      onChange={handlePlaybackSpeedChange}
     >
      <option value={0.5} className={Styles.playback_options}>
       0.5x
      </option>
      <option value={0.75} className={Styles.playback_options}>
       0.75x
      </option>
      <option value={1} className={Styles.playback_options}>
       1x
      </option>
      <option value={1.25} className={Styles.playback_options}>
       1.25x
      </option>
      <option value={1.5} className={Styles.playback_options}>
       1.5x
      </option>
      <option value={1.75} className={Styles.playback_options}>
       1.75x
      </option>
      <option value={2} className={Styles.playback_options}>
       2x
      </option>
     </select>
     {/* <TbArrowForwardUp
            size={20}
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key == "Enter") {
                handleFastForward();
              }
            }}
            onClick={handleFastForward}
            className={Styles.video_forward}
          /> */}
     <img
      height={20}
      width={20}
      src={forward}
      // tabIndex={0}
      // onKeyDown={(event) => {
      //   if (event.key == "Enter") {
      //     handleFastForward();
      //   }
      // }}
      onClick={handleFastForward}
      className={Styles.video_forward}
     />
     <ElapseTimeTrack elapseTime={elapseTime} duration={totalDuration} />
    </div>
    {/* <div className={Styles.controls_two}></div> */}
    <div className={Styles.controls_three}>
     <div
      ref={volumeControlRef}
      className={Styles.soundbar_container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
     >
      {volume > 0 ? (
       <HiOutlineSpeakerWave
        className={Styles.volumes}
        size={28}
        onClick={toggleVolumeBar}
        // tabIndex={0}
        // onKeyDown={(event) => {
        //   if (event.key == "Enter") {
        //     toggleVolumeBar();
        //   }
        // }}
       />
      ) : (
       <GoMute
        size={28}
        className={Styles.volumes}
        onClick={toggleVolumeBar}
        // tabIndex={0}
        // onKeyDown={(event) => {
        //   if (event.key == "Enter") {
        //     toggleVolumeBar();
        //   }
        // }}
       />
      )}
      <div className={Styles.soundBar} style={{ display: isHovered ? "block" : "none" }}>
       <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={handleVolumeChange}
        style={{ cursor: "pointer" }}
        className={Styles.volume_slider_vertical}
       />
      </div>
     </div>
     <div className={Styles.sub_fullscreen}>
      <img
       height={24}
       width={28}
       src={subtitles}
       className={Styles.subtitles}
       // tabIndex={0}
       // onKeyDown={(event) => {
       //   if (event.key == "Enter") {
       //     return;
       //   }
       // }}
      />
      <img
       height={24}
       width={28}
       src={fullscreen}
       className={Styles.fullScreen}
       // tabIndex={0}
       // onKeyDown={(event) => {
       //   if (event.key == "Enter") {
       //     handleFullScreen();
       //   }
       // }}
       onClick={handleFullScreen}
      />
     </div>
    </div>
   </div>
  </div>
  </>
 );
};

const ElapseTimeTrack = (props) => {
 const elapseTimeMin = Math.floor(props.elapseTime / 60);
 const elapseTimeSec = Math.floor(props.elapseTime % 60);
 const totalTimeMin = Math.floor(props.duration / 60);
 const totalTimeSec = Math.floor(props.duration % 60);

 return (
  <div className={Styles.video_length}>
   <div className={Styles.timeTackWidth}>
    {elapseTimeMin.toString().padStart(2, "0")}:{elapseTimeSec.toString().padStart(2, "0")}
   </div>
   <div style={{ paddingInline: "5px" }}>/</div>
   <div>
    {totalTimeMin.toString().padStart(2, "0")}:{totalTimeSec.toString().padStart(2, "0")}
   </div>
  </div>
 );
};

export default VideoPlayer;
