import { useEffect, useState } from "react";
import queryString from "query-string";
import CardStyle from "../../component/css/CourseCard.module.css";
import countryToCurrency from "country-to-currency";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Course_Card = (data) => {
 const { session_details } = useSelector((state) => state.applicationState);
 const [currencyValue, setCurrencyValue] = useState("AED");
 const navigate = useNavigate();
 let ele = data?.course_data;

 useEffect(() => {
  setCurrencyValue(countryToCurrency[session_details.cc]);
 }, []);

 const handleClick = () => {
//   const dataObject = {
//    courseID: ele?._id,
//   };
  const dataString = JSON.stringify(ele?._id);
  console.log("search course data ====== ", dataString);
  const queryStringified = queryString.stringify({ data: dataString });
  navigate(`/courseContent?${queryStringified}`);
 };

 return (
  <div className={CardStyle.card_View} onClick={() => handleClick()}>
   <div className={CardStyle.course_Image_View}>
    <img
     src={ele.thumnail_image}
     crossOrigin="anonymous"
     className={CardStyle.course_Image_Size}
    />
   </div>

   <div className={CardStyle.sub_Price_View}>
    <div className={CardStyle.course_Sub_Name_View}>
     <div className={CardStyle.course_Sub_Name_Text}>{ele.Subject}</div>
    </div>
    <div className={CardStyle.course_Price_Text}>{currencyValue + " " + ele.Price?.[currencyValue]}</div>
   </div>

   <div className={CardStyle.course_Name}>{ele.CourseName}</div>

   <div className={CardStyle.course_Description}>{ele.Description}</div>

   <div className={CardStyle.exploreButton_View}>
    <div className={CardStyle.exploreButton_Text}>{"Explore"}</div>
   </div>
  </div>
 );
};

export default Course_Card;
