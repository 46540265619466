import React, { useEffect } from "react";
import axios from "axios";

const PayPalButton = (props) => {
 console.log("PayPalButton props ==", props);
 useEffect(() => {
  window.paypal
   .Buttons({
    createOrder: async function () {
     try {
      const res = await axios.post("http://localhost:51111/purchase/pay_order_ppl", {
       amount: props.Price,
      });
      console.log("Create PayPal order response: ", res.data); // Debug log
      return res.data.id;
     } catch (error) {
      console.error("Error creating PayPal order: ", error);
      alert("Failed to create PayPal order.");
     }
    },
    onApprove: async function (data) {
     try {
      const res = await axios.post("http://localhost:51111/purchase/capture_order_ppl", {
       orderID: data.orderID,
      });
      console.log("Capture PayPal payment response: ", res.data); // Debug log
      if (res.data && res.status === 200) {
       alert("Payment successful!");
      } else {
       alert("Payment failed.");
      }
     } catch (error) {
      console.error("Error capturing PayPal order: ", error);
      alert("Payment capture failed.");
     }
    },
    onError: (err) => {
     console.error("PayPal button error: ", err);
     alert("Something went wrong with the payment.");
    },
   })
   .render("#paypal-button-container");
 }, []);

 return <div id="paypal-button-container"></div>;
};

export default PayPalButton;
