import { useEffect, useLayoutEffect, useState } from "react";
import Assessment_Svg from "../../assets/svg/Assessment_Svg";
import Document_Svg from "../../assets/svg/Document_Svg";
import Video_Svg from "../../assets/svg/Video_Svg";
import Styles from "../css/Cart_Screen.module.css";
import { API_URL } from "../../api/URL/Api_Route";
import { PostMethod, newPostMethod, DeleteMethodCart, GetMethodCartCourse, PostMethodTax } from "../../api/request/Api_Calls";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RunTimeConstant from "../../config/RuntimeConfig";
import NetworkError from "../../component/javascript/Network_Error";
import Loader from "../../component/javascript/loader";
import { cartItemCount, tabId, removeFromCart } from "../../redux/action";
import PaypalButton from "../../component/javascript/PayPal_Payment";
import queryString from "query-string";
import countryToCurrency, { Currencies, Countries } from "country-to-currency";
import { taxData } from "../../config/Constant";

let totalPayment;

const Cart_Screen = () => {
 const navigate = useNavigate();
 const dispatch = useDispatch();
 //  const location = useLocation();
 //  //  const { course_id } = useParams();
 //  const queryParams = queryString.parse(location.search);
 //  const parsedData = JSON.parse(queryParams?.data);

 const { token, cart_item_count, session_details } = useSelector((state) => state.applicationState);

 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const [cart_Data, set_Cart_Data] = useState([]);
 const [taxData, setTaxData] = useState({
  CountryCode: "",
  TaxDetails: [],
  CourseAmount: 0,
  TotalTaxAmount: "0",
  TotalCourseAmount: "0"
});

 const [all_Course_Id, set_All_Course_Id] = useState([]);
 const [is_Small_Screen, set_Is_Small_Screen] = useState(false);
 const [showPayment, setShowPayment] = useState(false);
 const [currencyValue, setCurrencyValue] = useState("AED");

/* integration by samir */
const getCartData = () => {
    set_Is_Loading(true);
    let payload = {
      url: API_URL.get_from_cart,
      data: {
        UserID: token,
      },
    };
  
    GetMethodCartCourse(payload)
      .then(async (response) => {
        console.log("getCartData response inside cart", response);
        if (response.status === 200) {
          const new_data = response?.data?.data // Array of course IDs
          console.log("new_data inside cart === ", new_data);
  
          // Fetch course details for each course ID
          const courseDetailsPromises = new_data.map((courseID) => getCourseDetails(courseID));
  
          // Wait for all course details to be fetched
          const courseDetailsArray = await Promise.all(courseDetailsPromises);
  
          let exist_data = [];
          courseDetailsArray.forEach((ele) => {
            ele.thumnail_image = `${RunTimeConstant.SERVICEURL.CS}assert/image?id=${encodeURIComponent(
             ele.AssetPath + ele.Image
            )}`;
            exist_data.push(ele);
           });

           console.log("cart data =========", exist_data)
          set_Cart_Data(exist_data); // Update the state with the course details
          set_Is_Loading(false);
        } else {
          set_Is_Loading(false);
          toast.dismiss();
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.log("getCartData error", error);
        set_Is_Loading(false);
        set_Is_Network_Error(true);
      });
  };
  
  // Updated getCourseDetails function to accept a course ID
  const getCourseDetails = (courseID) => {
    return new Promise((resolve, reject) => {
      let payload = {
        url: API_URL.getCourseById,
        data: JSON.stringify({
          _id: courseID,
          cc: session_details?.cc,
          page: 1,
          limit: 1,
        }),
      };
  
      PostMethod(payload)
        .then((response) => {
          console.log(`getCourseDetails response for ID ${courseID}`, response.data[0]);
          if (response.success) {
            resolve(response.data[0]); // Resolve with the course details
          } else {
            reject(new Error(response.message)); // Reject in case of an error response
          }
        })
        .catch((error) => {
          console.log("getCourseDetails error", error);
          reject(error); // Reject with the error
        });
    });
  };

  /* GetTax Data */
  const getTaxDetails = () => {
    set_Is_Loading(true)
    const courseAmount = cart_Data.reduce((sum, item) => sum + item.Price?.[currencyValue], 0);
    let payload = {
      url: API_URL.get_tax_details,
      data: JSON.stringify({
        CountryCode: session_details?.cc,
        CourseAmount: courseAmount,
      }),
    };
  
    PostMethodTax(payload)
      .then((response) => {
        if (response.success) {
          console.log(`getTaxDetails response`, response.data);
          setTaxData(response.data);
          set_Is_Loading(false)
        } else {
          toast.error(response?.message);
          set_Is_Loading(false)
        }
      })
      .catch((error) => {
        set_Is_Loading(false)
        console.log("getTaxDetails error", error);
        set_Is_Network_Error(true);
      });
  };
  
 const handleResize = () => {
  if (window.innerWidth <= 767) {
   set_Is_Small_Screen(true);
  } else {
   set_Is_Small_Screen(false);
  }
 };

 useEffect(() => {
  setCurrencyValue(countryToCurrency[session_details?.cc]);
  getCartData();
 }, [is_Network_Error]);


 useEffect(() => {
  if (cart_Data.length > 0) { // Only call getTaxDetails if cart_Data has items
    getTaxDetails();
  }
}, [cart_Data]); // Dependency on cart_Data


 //  useLayoutEffect(() => {
 //   if (parsedData?.statusCode == 200) {
 //    buyCourse();
 //   }
 //  }, []);

 useEffect(() => {
  dispatch(tabId({ tab_Id: 4 }));
  window.addEventListener("resize", handleResize);

  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 const removeCart = (course_id, count) => {
  let payload = {
   url: API_URL.remove_from_cart,
   data: {
    UserID: session_details?.sub,
    CourseID: course_id,
   },
  };
  DeleteMethodCart(payload)
   .then(async (response) => {
    console.log("removeCart response", response);
    if (response.status === 200) {
      const courseIds = Array.isArray(course_id) ? course_id : [course_id];
      courseIds.map((id) => {
        console.log("Removing CourseID === >>>> ", id);
        dispatch(removeFromCart(id));
      });
     dispatch(cartItemCount({ cart_item_count: count ? 0 : cart_item_count - 1 }));
     getCartData();
     if (!count) {
      toast.dismiss();
      toast.success("Item removed from cart");
     }
    } else {
     toast.dismiss();
     toast.error("Item not Deleted");
    }
   })
   .catch((error) => {
    console.log("removeCart error", error);
    set_Is_Network_Error(true);
   });
 };

 const buyCourse = () => {
  set_Is_Loading(true);
  setShowPayment(false);
  let payload = {
   url: API_URL.multiplePurchase,
   data: {
    _id: token,
    CourseID: all_Course_Id,
    Type: 1,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("buyCourse response", response);
    if (response.success) {
     // dispatch(cartItemCount({cart_item_count: 0}))
     removeCart(all_Course_Id, true);
     toast.dismiss();
     toast.success(response.message);
     // set_Is_Loading(false)

     // getCartData()
    } else {
     toast.dismiss();
     toast.error(response.message);
     set_Is_Loading(false);
    }
   })
   .catch((error) => {
    set_Is_Loading(false);
    console.log("buyCourse error", error);
    set_Is_Network_Error(true);
   });
 };

 const goToPayment = () => {

  cart_Data.forEach((course) => {
   course.currencyValue = currencyValue;
   course.cartCount = cart_Data.length;
  });

  const dataString = JSON.stringify(cart_Data);
  const queryStringified = queryString.stringify({ cart: dataString });
  navigate(`/payment1?${queryStringified}`);
 };

 const handleClick = (cartCourseID) => {

  const dataString = JSON.stringify(cartCourseID);
  const queryStringified = queryString.stringify({ data: dataString });
  navigate(`/courseContent?${queryStringified}`);
 };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 //  if (showPayment) {
 //   return (
 //    <PaypalButton
 //     totalPayment={totalPayment}
 //     onSubmit={() => buyCourse()}
 //     closePayment={() => setShowPayment(false)}
 //    />
 //   );
 //  }

 return (
  <div className={Styles.cart_Container}>
   {cart_Data.length ? (
    <>
     <div className={Styles.cart_Title_View}>
      <div className={Styles.cart_Title}>
       {cart_Data.length +
        "  " +
        (cart_Data.length <= 1 ? "course" : "courses") +
        " " +
        "in cart"}
      </div>
     </div>
     <div className={Styles.cart_Container_Body}>
      <div className={Styles.cart_Section_1}>
       {cart_Data.map((ele, ind) => {
        return (
         <div key={ind} className={Styles.cart_Card_View} onClick={() => handleClick(ele?._id)}>
          <div className={Styles.cart_Image_View}>
           <img
            src={ele.thumnail_image}
            crossOrigin="anonymous"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
           />
          </div>

          <div className={Styles.cart_Card_Content_View_1}>
           <div>
            <div className={Styles.courseName_Text}>{ele.CourseName}</div>
            {!is_Small_Screen ? (
             <div className={Styles.subject_View}>
              <div className={Styles.subject_Text}>{ele.Subject}</div>
             </div>
            ) : (
             <div className={Styles.subject_Price_View}>
              <div className={Styles.subject_Text}>{ele.Subject}</div>

              <div className={Styles.course_Price_Text}>{currencyValue + " " + ele.Price?.[currencyValue]}</div>
             </div>
            )}
           </div>

           <div className={Styles.assests_View}>
            <Video_Svg width={"16"} height={"13"} color={"var(--shadow-color_2)"} />{" "}
            <span className={Styles.assesst_Text}>
             {ele.NumberOfVideos} {"Videos"}
            </span>
            {/* <Document_Svg width={"16"} height={"13"} color={"var(--shadow-color_2)"}/> <span className={Styles.assesst_Text}>{ele.NumberOfDocuments? ele.NumberOfDocuments: 0} {"Documents"}</span> */}
            <Assessment_Svg width={"16"} height={"13"} color={"var(--shadow-color_2)"} />{" "}
            <span className={Styles.assesst_Text}>
             {ele.NumberOfAssessment} {"Assessments"}
            </span>
           </div>
          </div>

          {!is_Small_Screen ? (
           <div className={Styles.cart_Card_Content_View_2}>
            <div className={Styles.cart_Card_Content_View_2_Price_Text}>
             {currencyValue + " " + ele.Price?.[currencyValue]}
            </div>

            <div
             className={Styles.remove_Text}
             onClick={(e) => {
              e.stopPropagation();
              removeCart([ele?._id]);
             }}
            >
             Remove
            </div>
           </div>
          ) : (
           <div>
            <div className={Styles.remove_Text} onClick={() => removeCart([ele?._id])}>
             Remove
            </div>
           </div>
          )}
         </div>
        );
       })}
      </div>

      <div className={Styles.cart_Section_2}>
       <div className={Styles.cart_Section_2_Title_View}>
        <div className={Styles.cart_Section_2_Title}>Billing Details</div>
       </div>

       <div className={Styles.cart_Section_2_Content_View}>
        <div className={Styles.cart_Section_2_Content_Title}>
         {cart_Data.length + "  " + (cart_Data.length <= 1 ? "Course" : "Courses")}
        </div>

        {cart_Data.map((ele, ind) => {
         return (
          <>
          <div key={ind} className={Styles.cart_Section_2_Course_List}>
           <div className={Styles.cart_Section_2_Course_Name}>{ele.CourseName}</div>
           <div className={Styles.cart_Section_2_Course_Price}>{currencyValue + " " + ele.Price?.[currencyValue]}</div>
          </div>
           </>
         );
        })}

{taxData.TaxDetails.map((data, index) => (
    <div key={index} className={Styles.cart_Section_2_Course_List}>
      <div className={Styles.cart_Section_2_Tax_Name}>
        <div className={Styles.cart_Section_2_Tax_container}>
          <div>{data.type}</div>
          <div>{data.Percentage}%</div>
        </div>
      </div>
      <div className={Styles.cart_Section_2_Tax_Price}>
        {currencyValue} {data.TaxAmount}
      </div>
    </div>
  ))}

        <div className={Styles.cart_Section_2_Line_Style} />

        {/* <div className={Styles.total_Count_View}>
         <div className={Styles.total_Count_Text}>Total</div>

         {(() => {
          totalPayment = cart_Data.reduce((a, b) => a + b.Price?.[currencyValue], 0);
          return <div className={Styles.total_Price_Text}>{currencyValue + " " + totalPayment}</div>;
         })()}
        </div> */}

<div className={Styles.total_Count_View}>
    <div className={Styles.total_Count_Text}>Total</div>
    <div className={Styles.total_Price_Text}>{currencyValue + " " + taxData.TotalCourseAmount}</div>
  </div>

        {/* <div className={Styles.coupon_View}>
            <input
                placeholder="Enter Coupon"
                className={Styles.coupon_Text_input}
            />

            <div className={Styles.coupon_Button_View}>
                <div className={Styles.coupon_Button_Text}>
                    Apply
                </div>
            </div>
        </div> */}

        <div
         onClick={() => {
          // buyCourse()
          // setShowPayment(true);
          goToPayment();
         }}
         className={Styles.buyNow_Button_View}
        >
         <div className={Styles.buyNow_Button_Text}>Buy Now</div>
        </div>
       </div>
      </div>
     </div>
    </>
   ) : (
    <div className={Styles.empty_Cart_Container}>
     {/* <div className={Styles.no_Item_Text}>
                        No Item Selected
                    </div> */}

     <div className={Styles.cart_Center_View}>
      <div className={Styles.no_Item_Text}>Your cart is empty</div>

      <div onClick={() => navigate("/allCourse")} className={Styles.explore_Now_Button_View}>
       <div className={Styles.explore_Now_Text}>Explore now</div>
      </div>
     </div>
    </div>
   )}
  </div>
 );
};

export default Cart_Screen;
