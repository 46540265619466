import React, { useEffect, useState, useRef } from "react";
import Styles from "../css/RazorPay_Payment.module.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import toast from "react-hot-toast";
import { FcCancel } from "react-icons/fc";
import CartStyles from "../../pages/css/Cart_Screen.module.css";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API_URL, MESSAGING_SERVICE_ROUTE } from "../../api/URL/Api_Route";
import { NewPostMethod, PostMethod, PostMethodTax } from "../../api/request/Api_Calls";
import { cartItemCount } from "../../redux/action";
import Loader from "./loader";
import NetworkError from "./Network_Error";
import RunTimeConstant from "../../config/RuntimeConfig";
import PayPalButton from "./PayPal_Button";
import WS from "../../config/WS";
import Paylogo from "../../assets/image/pay_screen_logo.png"
import PayFailedlogo from "../../assets/image/payment_failed_logo.png"
import PaySuccesslogo from "../../assets/image/payment_success_logo.png"
import PayLoader from "../javascript/PayLoader"
import { taxData } from "../../config/Constant";

let totalPayment;

const Payment_Screen1 = () => {
 const location = useLocation();
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const [isPaymentStarted, setIsPaymentStarted] = useState(false);
 const queryParams = queryString.parse(location.search);
 const payment_data = JSON.parse(queryParams.cart);
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const { token, cart_item_count, session_details } = useSelector(
  (state) => state.applicationState
 );

 const [orderData, setOrderData] = useState(null);
 const [countryCode, setCountryCode] = useState(session_details.cc);
 const [showPayLoader, setShowPayLoader] = useState(false);
 const [showPayBtn, setShowPayBtn] = useState(true);
 const [payHeading, setPayHeading] = useState("Prepare for Payment");
 const [PayBtnText, setPayBtnText] = useState("Proceed to Payment");
 const [SecondaryBtnText, setSecondaryBtnText] = useState("Back");
 const [connectSocket, setConnectSocket] = useState(false);
 const [taxData, setTaxData] = useState({
  CountryCode: "",
  TaxDetails: [],
  CourseAmount: 0,
  TotalTaxAmount: "0",
  TotalCourseAmount: "0"
});

 const wsRef = useRef(null); // Reference for the WebSocket instance

 // Define handlers for WebSocket events
 const handleWSOpen = () => {
  console.log("WebSocket connection established");
  if (wsRef.current) {
   let data = { UserID: session_details?.sub, payload: { action: "FIRST_CONNECT" } };
   let stringifiedData = JSON.stringify(data);
   wsRef.current.send(stringifiedData);
  }
 };


 const getTaxDetails = () => {
  set_Is_Loading(true)
  const courseAmount = payment_data.reduce((sum, item) => sum + item.Price.INR, 0);
  let payload = {
    url: API_URL.get_tax_details,
    data: JSON.stringify({
      CountryCode: session_details?.cc,
      CourseAmount: courseAmount, // Use calculated amount
    }),
  };

  PostMethodTax(payload)
    .then((response) => {
      if (response.success) {
        console.log(`getTaxDetails response`, response.data);
        setTaxData(response.data); // Update state with full tax data structure
        set_Is_Loading(false)
      } else {
        toast.error(response.message);
      }
    })
    .catch((error) => {
      set_Is_Loading(false)
      console.log("getTaxDetails error", error);
      set_Is_Network_Error(true);
    });
};

useEffect(() => {
  if (payment_data?.length > 0) {
    getTaxDetails();
  }
}, []); 


 const handleWSMessage = (event) => {
  console.log(`Message from server: ${event.data}`);
  let parsedData = JSON.parse(event.data);
  switch (parsedData?.payload?.action) {
   case "ORDER_CREATION_SUCCESS":
    console.log("ORDER_CREATION_SUCCESS", parsedData);
    setOrderData(parsedData.payload.data);
    console.log("someting=================================>", parsedData.payload.data);
    console.log("orderData.id === 1 ", orderData?.id);
    openPaymentWindow(); // open payment portal using order id
    setShowPayLoader(true)
    setShowPayBtn(false)
    setPayHeading("Payment Processing")
    break;
   case "DISPLAY_ORDER_SUCCESS":
    console.log("DISPLAY_ORDER_SUCCESS ========>", parsedData.payload.data);
    dispatch(cartItemCount({ cart_item_count: cart_item_count - payment_data[0]?.cartCount }));
    paymentCompleted();
    break;
   case "ORDER_PENDING_FAILURE":
    console.log("ORDER_PENDING_FAILURE ===== ",parsedData.payload.data);
    // alert("ORDER_PENDING_FAILURE");
    setShowPayLoader(false)
    setShowPayBtn(true)
    setPayHeading("Payment couldn't process")
    setPayBtnText("Retry Payment")
    setSecondaryBtnText("Need help?")
    break;
    case "PAYMENT_UPDATE_FAILED":
      // alert("PAYMENT_FAILURE")
      paymentFailureReceived()
    break;

   default:
    break;
  }
 };

 

 const handleWSError = (error) => {
  console.log("WebSocket encountered an error:", error);
 };

 const handleWSClose = () => {
  console.log("WebSocket connection closed");
 };

 //  const sendData = () => {
 //   const data = {
 //    UserID: "yourUserID", // Replace with actual user ID
 //    payload: { action: "YOUR_ACTION" },
 //   };
 //   const stringifiedData = JSON.stringify(data);

 //   // Use the send method of the WS component
 //   wsRef.current.send(stringifiedData);
 //  };

 const closePayment = () => {
  navigate(-1);
 };

//  const removeCart = (course_id, count) => {
//   let payload = {
//    url: API_URL.remove_from_cart,
//    data: {
//     UserID: session_details?.sub,
//     CourseID: course_id,
//    },
//   };
//   DeleteMethodCart(payload)
//    .then(async (response) => {
//     console.log("removeCart response === ", response);
//     if (response.status === 200) {
//      dispatch(cartItemCount({ cart_item_count: count ? 0 : cart_item_count - 1 }));
//      getCartData();
//      if (!count) {
//       toast.dismiss();
//       toast.success("Item removed from cart === ");
//      }
//     } else {
//      toast.dismiss();
//      toast.error("Item not Deleted === ");
//     }
//    })
//    .catch((error) => {
//     console.log("removeCart error === ", error);
//     set_Is_Network_Error(true);
//    });
//  };

 const buyCourse = (course_id, Is_present_incart, isFrom) => {
  set_Is_Loading(true);
  let payload = {
   url: isFrom == "single" ? API_URL.updatePurchase : API_URL.multiplePurchase,
   data: {
    _id: token,
    CourseID: course_id,
    Type: 1,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("buyCourse response", response);
    if (response.success) {
     if (isFrom == "single") {
      const dataObject = {
       CourseID: course_id,
      };
      const dataString = JSON.stringify(dataObject);
      const queryStringified = queryString.stringify({ data: dataString });
      navigate(`/courseContent?${queryStringified}`);
      if (Is_present_incart) {
      //  removeCart([course_id]);
      }
     } else if (isFrom == "bulk") {
      // removeCart(course_id);
      navigate("/cart");
     }
     // set_Is_Loading(false);
     toast.dismiss();
     toast.success(response.message);
    } else {
     toast.dismiss();
     toast.error(response.message);
     set_Is_Loading(false);
    }
   })
   .catch((error) => {
    console.log("buyCourse error", error);
    set_Is_Network_Error(true);
    set_Is_Loading(false);
   });
 };

 //  const onSubmit = () => {
 //   if (payment_data?.statusCode == 100) {
 //    buyCourse(payment_data?.data[0]?._id, payment_data?.data[0]?.IsPresentIncart, "single");
 //   } else if (payment_data?.statusCode == 101) {
 //    buyCourse(payment_data?.allCourseID, false, "bulk");
 //    //  navigate("/cart");
 //   }
 //  };

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 console.log("payment_data === ", payment_data);

 const paymentCompleted = async () => {
  setPayHeading("Payment Processing")
  setShowPayLoader(true)
  if (wsRef.current) {
   let data = {
    UserID: session_details?.sub,
    payload: {
     action: "CLOSE_CONNECTION",
     origin: "INITIATE_PAYMENT",
     msg: "please close the socket connection as payment successfully completed",
    },
   };
   console.log("payload222222 ", data);
   let stringifiedData = JSON.stringify(data);
   wsRef.current.send(stringifiedData);
   setShowPayLoader(false)
   setPayHeading("Payment Successful!")
   setPayBtnText("Start Learning")
   setSecondaryBtnText("Go to Homepage")
   setShowPayBtn(true)
  }
 };

 const paymentFailureReceived = async () => {
  setShowPayLoader(true)
  if (wsRef.current) {
   let data = {
    UserID: session_details?.sub,
    payload: {
     action: "CLOSE_CONNECTION",
     origin: "PAYMENT_FAILURE_RECEIVE",
     msg: "please close the socket connection as payment failed while updating records",
    },
   };
   console.log("payload33333", data);
   let stringifiedData = JSON.stringify(data);
   wsRef.current.send(stringifiedData);
   setShowPayLoader(false)
   setShowPayBtn(true)
   setPayHeading("Payment Failed")
   setPayBtnText("Retry Payment")
   setSecondaryBtnText("Need help?")
  }
 };

 const openPaymentWindow = async () => {
  let odData = null;

  setOrderData(async (nextState) => {
   odData = nextState;
   console.log("nextState: ", nextState);
   if (odData) {
    console.log("orderData.id === 2 ", odData?.id);
    const options = {
     key: process.env.RAZOR_PAY_KEY_ID || "rzp_test_FGvr5MQAZ5SSbr",
     name: "CognitiveED",
     description: "Welcome to CognitivED. Empowering Students CognitivED",
     image:
      "https://res.cloudinary.com/dt9pywe5q/image/upload/v1727958347/cognitivLogo_cyaiul.png",
     order_id: odData?.id, // Make sure 'data.id' contains a valid order ID
     handler: async (response, error) => {
      try {
       console.log("pay handler response === ", response);
       const PaymentId = response.razorpay_payment_id;
       console.log("PaymentId ===> ", PaymentId);
       try {
        if (wsRef.current) {
         let data = {
          UserID: session_details?.sub,
          payload: {
           action: "PAYMENT_SUCCED",
           origin: "INITIATE_PAYMENT",
           PaymentId: PaymentId,
          // Amount: payment_data.reduce((total, course) => total + course?.Price?.[course.currencyValue], 0),
          Amount: taxData.TotalCourseAmount,
          Currency: payment_data[0]?.currencyValue, // Currency
          PurchaseID: odData?.PurchaseID,
          },
         };
         console.log("payload11111 ", data);
         let stringifiedData = JSON.stringify(data);
         wsRef.current.send(stringifiedData);
        }
       } catch (error) {
        console.log("error: ===========>", error);
       }
      } catch (err) {
       console.error("Error capturing payment: **************", err);
      }
     },
     theme: {
      color: "#686CFD",
     },
     modal: {
      ondismiss: () => {
        setShowPayLoader(false)
        setShowPayBtn(true)
        setPayHeading("Prepare for Payment")
        setPayBtnText("Proceed to Payment")
       console.log("User closed the payment modal without completing the payment");
       if (wsRef.current) {
        let data = {
         UserID: session_details?.sub,
         payload: {
          action: "PAYMENT_CANCLED_BY_USER",
          origin: "INITIATE_PAYMENT",
          PaymentId: "",
          PurchaseID: odData?.PurchaseID,
         },
        };
        console.log("payload11112 ", data);
        let stringifiedData = JSON.stringify(data);
        wsRef.current.send(stringifiedData);
       }
      },
     },
    };

    // Create a new Razorpay instance and open the payment form
    const rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
     console.log("Payment failed:", response.error);
     if (wsRef.current) {
      let data = {
       UserID: session_details?.sub,
       payload: {
        action: "PAYMENT_NOT_COMPLETED",
        origin: "INITIATE_PAYMENT",
        msg: "Payment could not be completed",
       },
      };
      console.log("payload11113 ", data);
      let stringifiedData = JSON.stringify(data);
      wsRef.current.send(stringifiedData);
     }
    });
    rzp1.open();
   } else {
    console.log("");
   }
   return nextState;
  });
 };

 const paymentHandler = async (e) => {
  e.preventDefault();
  setConnectSocket(true)
  try {
    setShowPayLoader(true)
    setShowPayBtn(false)
    setPayHeading("Payment Processing")
    const CREATE_ORDER = {
      UserID: session_details?.sub,
      // UserID: "session_details?.sub", // for failure scenario test
      Courses: payment_data.map((course) => ({
        CourseID: course._id,
        Validity: "2024-10-01T11:17:13.124Z",
        Amount: course.Price?.[course.currencyValue],
        Currency: course.currencyValue
      })),
      // TotalAmount: payment_data.reduce((total, course) => total + course?.Price?.[course.currencyValue], 0), // Summing the prices
      TotalAmount: taxData.TotalCourseAmount,
      CurrencyValue: payment_data[0]?.currencyValue, // Currency
    };

    console.log("CREATE_ORDER Payload ==== ", CREATE_ORDER)

   const payload = {
    baseURL: RunTimeConstant.SERVICEURL.MS,
    url: MESSAGING_SERVICE_ROUTE.CREATE_ORDER, 
    data: CREATE_ORDER,
    withCredentials: false,
   };
   console.log("order msg payload ===> ", payload);
   try {
    let resOrder = await NewPostMethod(payload);
    console.log("order response ===> ", resOrder);
   } catch (error) {
    setShowPayLoader(false)
    setShowPayBtn(true)
    setPayHeading("Payment couldn't process")
    setPayBtnText("Retry Payment")
    setSecondaryBtnText("Need help?")
    console.log("error --- >", error);
   }
  } catch (error) {
  setConnectSocket(false)
   console.error("Error in paymentHandler:", error);
  }
 };

const PaymentSection = ({ showPayLoader, payHeading, PayBtnText, showPayBtn, SecondaryBtnText }) => {
  return (
    <div  className={Styles.paymentContainerMobileInsideView}>
    <div style={{width:"80%", display:"flex", justifyContent:"space-around", alignItems:"center", flexDirection:"column"} }> 
    
    {showPayLoader ? <PayLoader/> :  <img
  src={
    payHeading === "Prepare for Payment"
      ? Paylogo
      : payHeading === "Payment Successful!"
      ? PaySuccesslogo
      : PayFailedlogo
  }
  width={80}
  height={80}
/>}
      
    {/* <h5 style={{color:"#170F49", fontSize:"20px"}}>{payHeading}</h5> */}

    <h5
  style={{
    color:
      payHeading === "Payment couldn't process" || payHeading === "Payment Failed"
        ? "#DF615C"
        : payHeading === "Payment Successful!"
        ? "#21B573"
        : "#170F49", // default color if none of the conditions match
    fontSize: "20px"
  }}
>
  {payHeading}
</h5>

    {(payHeading === "Prepare for Payment" || payHeading === "Payment Processing") && (
  <p style={{ color: "#6F6C90", fontWeight: "400", textAlign: "center", fontSize: "14px" }}>
    <span style={{ fontWeight: "600" }}>
      Please do not refresh, go back, or close this page
    </span>{' '}
    during the payment process. This ensures that your transaction completes smoothly.
  </p>
)}

{payHeading === "Payment couldn't process" && <p style={{color:"#6F6C90", fontWeight:"400", textAlign:"center", fontSize:"14px"}}>Unfortunately, your payment could <span  style={{fontWeight:"600"}}>not be processed</span>.  Please try <span style={{fontWeight:"600"}}>again or contact support</span>. </p>}

{payHeading === "Payment Successful!" && <p style={{color:"#6F6C90", fontWeight:"600", textAlign:"center", fontSize:"14px"}}>Your transaction was completed successfully. You can now access your courses.</p>}

{payHeading === "Payment Failed" && <p style={{color:"#6F6C90", fontWeight:"400", textAlign:"center", fontSize:"14px"}}>Unfortunately, your payment could <span  style={{fontWeight:"600"}}>not be processed</span>.  Please try <span style={{fontWeight:"600"}}>again or contact support</span>.  If the amount has been deducted, the payment will be automatically <span style={{fontWeight:"600"}}>refunded</span>.</p>}

    </div>
    
    {showPayBtn && <button className={Styles.RZPbuttonStyle} onClick={PayBtnText === "Start Learning" ? () => navigate("/myCourses") : paymentHandler}>
          {PayBtnText}
          </button>}
          
          {payHeading !== "Payment Processing" && <div style={{fontWeight:"600", textAlign:"center", lineHeight:"20px", color:"#7445FF", borderBottom: "solid 0.5px #7445FF", cursor: 'pointer',}} onClick={
  payHeading === "Payment Successful!"
    ? () => navigate("/")
    : payHeading === "Payment couldn't process" || payHeading === "Payment Failed"
    ? () => navigate("/supportpage")
    : () => navigate(-1)
}>{SecondaryBtnText}</div>}
          
     
          </div>
  )
}

 return (
  <div className={Styles.paymentContainer}>
   {/* WebSocket component */}
   {connectSocket &&  <WS
    // url="ws://127.0.0.1:55555"
    url="wss://ftp.iinvsys.com:55576/CEDUMsgServiceWebSoc"

    // url="ws://192.168.10.155:55555"
    onOpen={handleWSOpen}
    onMessage={handleWSMessage}
    onClose={handleWSClose}
    onError={handleWSError}
    ref={wsRef} // Store WebSocket reference
    reconnect={true} // Optional: enable reconnection logic
   />}
  
   <div className={CartStyles.cart_Title_View}>
    <div className={CartStyles.cart_Title}>Payment Option</div>
   </div>
   <div className={Styles.paymentBody}>
    {countryCode !== "IN" ? (
     <div className={Styles.paymentContainerMobile}>
      <PayPalButton Price={payment_data.Price?.[[payment_data[0]?.currencyValue]]} />
     </div>
    ) : (
     <div className={Styles.paymentContainerMobile}>
 <PaymentSection showPayLoader={showPayLoader} payHeading={payHeading} PayBtnText={PayBtnText} showPayBtn={showPayBtn} SecondaryBtnText={SecondaryBtnText} />
     </div>
    )}

    <div className={CartStyles.cart_Section_2}>
     <div className={CartStyles.cart_Section_2_Title_View}>
      <div className={CartStyles.cart_Section_2_Title}>Billing Details</div>
     </div>

     <div className={CartStyles.cart_Section_2_Content_View}>
      <div className={CartStyles.cart_Section_2_Content_Title}>
       {payment_data?.length + "  " + (payment_data?.length <= 1 ? "Course" : "Courses")}
      </div>

      {payment_data?.map((ele, ind) => {
       return (
        <>
        <div key={ind} className={CartStyles.cart_Section_2_Course_List}>
           <div className={CartStyles.cart_Section_2_Course_Name}>{ele.CourseName}</div>
           <div className={CartStyles.cart_Section_2_Course_Price}>
             {ele?.currencyValue + " " + ele.Price?.[ele?.currencyValue]}
           </div>
         </div>
           </>
       );
      })}

{taxData.TaxDetails.map((data, index) => (
    <div key={index} className={Styles.cart_Section_2_Course_List}>
      <div className={Styles.cart_Section_2_Tax_Name}>
        <div className={Styles.cart_Section_2_Tax_container}>
          <div>{data.type}</div>
          <div>{data.Percentage}%</div>
        </div>
      </div>
      <div className={Styles.cart_Section_2_Tax_Price}>
        {payment_data[0]?.currencyValue} {data.TaxAmount}
      </div>
    </div>
  ))}

      <div className={CartStyles.cart_Section_2_Line_Style} />
      
      {/* <div className={CartStyles.total_Count_View}>
       <div className={CartStyles.total_Count_Text}>Total</div>

       {(() => {
        totalPayment = payment_data?.reduce((a, b) => a + b.Price?.[b?.currencyValue], 0);
        return (
         <div className={CartStyles.total_Price_Text}>
          {payment_data[0]?.currencyValue + " " + totalPayment}
         </div>
        );
       })()}
      </div> */}

<div className={Styles.total_Count_View}>
    <div className={Styles.total_Count_Text}>Total</div>
    <div className={Styles.total_Price_Text}>{payment_data[0]?.currencyValue + " " + taxData.TotalCourseAmount}</div>
  </div>

     </div>
    </div>
   </div>
  </div>
 );
};

export default Payment_Screen1;
