import { useParams, useNavigate, useLocation } from "react-router-dom";
import Styles from "../css/Search_Screen.module.css";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useLayoutEffect, useState } from "react";
import RunTimeConstant from "../../config/RuntimeConfig";
import { PostMethod } from "../../api/request/Api_Calls";
import toast from "react-hot-toast";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import { API_URL } from "../../api/URL/Api_Route";
import { useDispatch } from "react-redux";
import Course_Card from "../../component/javascript/CourseCard";
import { tabId } from "../../redux/action";
import { Pagination } from "@mui/material";
import { useSelector } from "react-redux";

const Search_Screen = () => {

const { session_details } = useSelector((state) => state.applicationState);
 const { src } = useParams();
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const [search_Value, set_Search_Value] = useState(src != "=" ? src : "");
 const [courseData, setCourseData] = useState([]);
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);
 const [searchParam, setSearchParam] = useState(src != "=" ? src : "");
 const [current_page, setCurrent_page] = useState(1);
 const [page_count, setPage_count] = useState(1);
 const [data_count, setData_count] = useState(0);

 const points = [
  "Try using broader search terms",
  "Check your spelling and try alternative keywords",
  "Use filters to narrow down your search results",
 ];

 useEffect(() => {
  if (search_Value != "") {
   onSearchEvent(search_Value);
  }
 }, [is_Network_Error, current_page]);

 const ScrollToTop = () => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
   window.scrollTo(0, 0);
  }, [pathname]);
  return null;
 };

 useEffect(() => {
  dispatch(tabId({ tab_Id: -1 }));
 }, []);

 const onSearchEvent = (data) => {
  console.log("onSearchEvent data", data);
  set_Is_Loading(true);
  // set_Search_Value(data)
  let payload = {
   url: API_URL.searchCourse,
   data: {
    seachName: data,
    page: current_page + "",
    limit: 10 + "",
    cc: session_details.cc,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("onSearchEvent response", response);
    if (response.success) {
     let exist_data = [];
     const new_data = response.data;
     new_data.forEach((ele) => {
      ele.thumnail_image = `${
       RunTimeConstant.SERVICEURL.CS
      }assert/image?id=${encodeURIComponent(ele.AssetPath + ele.Image)}`;
      exist_data.push(ele);
     });
     setPage_count(response?.totalPages);
     setData_count(response?.totalCount);
     setCourseData(exist_data);
     navigate(`/search/${data}`);
     set_Is_Loading(false);
    } else {
     toast.dismiss();
     toast(response.message);
     set_Is_Loading(false);
    }
   })
   .catch((error) => {
    set_Is_Network_Error(true);
    set_Is_Loading(false);
   });
 };

 // if(is_Loading){
 //     return (
 //       <Loader isLoading={is_Loading} callBack = {()=> set_Is_Loading(false)}/>
 //     )
 // }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 return (
  <div className={Styles.search_Container}>
   <ScrollToTop />
   <div className={Styles.search_Body}>
    <div>
     {!is_Loading && search_Value != "" && (
      <div className={Styles.results_Found_Text}>Total Results Found: {data_count}</div>
     )}
    </div>
    <div className={Styles.searchView}>
     <input
      type="text"
      placeholder="Search..."
      value={searchParam}
      onChange={(e) => setSearchParam(e.target.value)}
      className={Styles.search_input}
      onKeyDown={(event) => {
       if (event.key == "Enter") {
        // console.log("serr",event.target.value)
        if (event.target.value.trim()) {
         set_Search_Value(event.target.value);
         onSearchEvent(event.target.value);
        } else {
         navigate("/allCourse");
        }
       }
      }}
     />
     <AiOutlineSearch
      size={20}
      color="var(--primary-color)"
      style={{ marginRight: "3%" }}
      onClick={() => {
       if (searchParam.trim()) {
        set_Search_Value(searchParam);
        onSearchEvent(searchParam);
       }
      }}
     />
    </div>
   </div>

   {is_Loading ? (
    <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />
   ) : (
    <div>
     {courseData.length ? (
      <>
       <div className={Styles.course_Container}>
        {courseData.map((ele, ind) => {
         return <Course_Card key={ind} course_data={ele} />;
        })}
       </div>

       {page_count > 1 && (
        <div className={Styles.pagination_View}>
         <Pagination
          boundaryCount={1}
          count={page_count}
          defaultPage={1}
          page={current_page}
          onChange={(event, value) => setCurrent_page(value)}
         />
        </div>
       )}
      </>
     ) : search_Value != "" ? (
      <div className={Styles.no_Data_Found_Container}>
       <div className={Styles.no_Data_Found_Body}>
        <div className={Styles.no_Data_Points_Title}>
         Sorry, No Results Found for{" "}
         <span style={{ fontWeight: "600" }}>‘’{search_Value}’’</span>
        </div>
        {points.map((ele, ind) => {
         return (
          <div key={ind}>
           <ul>
            <li className={Styles.no_Data_Points}>{ele}</li>
           </ul>
          </div>
         );
        })}
       </div>
      </div>
     ) : (
      <div style={{ height: "100vh" }}></div>
     )}
    </div>
   )}
  </div>
 );
};

export default Search_Screen;
