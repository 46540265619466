import React, { useEffect, useRef, useState } from "react";
import Styles from "../css/SessionPopUp.module.css";
import warning from "../../assets/image/warning.png";
import { useNavigate } from "react-router-dom";
import { LogOut } from "../../redux/action";
import { useDispatch } from "react-redux";

const SessionPopUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const popupRef = useRef(null);

  const navigateLogIn = () => {
    dispatch(LogOut());
    navigate("/login");
  };
  const closePopup = () => {
    setIsOpen(false);
    document.body.style.overflow = "visible";
  };

  const handleOverlayClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup();
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <div className={Styles.sessionPopUp}>
      {isOpen && (
        <div className={Styles.overlay} onClick={handleOverlayClick}>
          <div className={Styles.popup} ref={popupRef}>
            <div className={Styles.popDetails}>
              <img
                src={warning}
                alt="Session Expired"
                className={Styles.popupImage}
                height={46}
                width={50}
              />
              <h2 className={Styles.sessionExpired}>Your session has expired</h2>
              <p className={Styles.logIn}>Pleasure log in again to continue</p>
              <button
                className={Styles.sessionLogInbutton}
                onClick={navigateLogIn}
                type="submit"
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionPopUp;
