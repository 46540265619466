import { useEffect, useState } from "react";
import Styles from "../../pages/css/Landing_Screen.module.css";
import App_Logo from "../../assets/svg/Application_Logo";
import Youtube_Svg from "../../assets/svg/Youtube_Svg";
import Facebook_Svg from "../../assets/svg/Facebook_Svg";
import Twitter_Svg from "../../assets/svg/Twitter_Svg";
import Instagram_Svg from "../../assets/svg/Instagram_Svg";
import { useNavigate } from "react-router-dom";


const Footer = () => {
    const navigate = useNavigate();


 const [social_Media_Icon, set_Social_Media_Icon] = useState([
  {
   id: 0,
   icon: <Youtube_Svg />,
   path: "https://www.youtube.com/",
  },
  {
   id: 1,
   icon: <Facebook_Svg />,
   path: "https://www.facebook.com/",
  },
  {
   id: 2,
   icon: <Twitter_Svg />,
   path: "https://x.com/?lang=en",
  },
  {
   id: 3,
   icon: <Instagram_Svg />,
   path: "https://www.instagram.com/",
  },
 ]);

 const [is_Small_Screen, set_Is_Small_Screen] = useState(false);

 const [subName, set_SubName] = useState([
  {
   id: 0,
   name: "Mathematics",
  },
  // {
  //     id:1,
  //     name:'Maths Advanced',
  // },
  // {
  //     id:1,
  //     name:'Physics',
  // },
  // {
  //     id:3,
  //     name:'Physics Advanced',
  // },
  // {
  //     id:2,
  //     name:'Chemistry',
  // },
  // {
  //     id:5,
  //     name:'Chemistry Advanced',
  // }
 ]);

 const [footer_Pages, set_Footer_Pages] = useState([
  {
   id: 0,
   name: "About",
   
  },
  // {
  //     id:1,
  //     name:'Careers',
  // },
  // {
  //     id:2,
  //     name:'pricing',
  // },
  {
   id: 1,
   name: "Support",
   route_name: "/supportpage",
  },
  {
   id: 2,
   name: "FAQs",
   route_name:"/faqpage"
  },
 ]);

//  const navigatingReference = (route) => {
//     navigate("/supportpage");
//     // setOpenMenu(false);
//    };


 const handleResize = () => {
  if (window.innerWidth < 992) {
   set_Is_Small_Screen(true);
  } else {
   set_Is_Small_Screen(false);
  }
  // console.log("window.innerWidth", window.innerWidth)

  // Check for landscape orientation with a max-width of 767 pixels
  // const isLandscapeOrientation = window.matchMedia(
  //   "(max-height: 575.98px) and (orientation: landscape)"
  // ).matches;
  // setIsLandscape(isLandscapeOrientation);
 };

 useEffect(() => {
  handleResize();
  window.addEventListener("resize", handleResize);
  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 return (
    
  <div className={Styles.footer_Container}>
   <div className={Styles.footer_Part_1}>
    <div className={Styles.app_Logo_View}>
     <div>
      <App_Logo />
     </div>
     <div className={Styles.app_Name_Text}>CognitivED</div>
    </div>

    <div className={Styles.social_Media_View}>
     {social_Media_Icon.map((ele, ind) => {
      return (
       <a
        style={{ paddingRight: "10px" }}
        key={ind}
        href={ele.path}
        target="_blank"
        rel="noopener noreferrer"
       >
        {ele?.icon}
       </a>
      );
     })}
    </div>

    <div className={Styles.footer_Copyright_Text}>
     ©️2024 <span className={Styles.font_Weight_700}>CognitivED</span>. All Rights Reserved.
    </div>
   </div>

   {!is_Small_Screen && (
    <div className={Styles.footer_Part_2}>
     <div className={Styles.footer_part_2_View_1}>
      <span style={{ fontWeight: "700", fontSize: "24px" }}>Learn</span>
      <br />
      {subName.map((ele, ind) => {
       return (
        <div style={{ paddingBottom: "13px" }} key={ind}>
            
         {ele?.name}
        </div>
       );
      })}
     </div>

     <div className={Styles.footer_part_2_View_2}>
      <span style={{ fontWeight: "700", fontSize: "24px" }}>CognitivED</span>
      <br />
      {footer_Pages.map((ele, ind) => {
       return (
        <div style={{ paddingBottom: "13px",cursor:'pointer' }} key={ind} onClick={()=>{navigate(ele.route_name)}}>
         {ele.name}
        </div>
       );
      })}
     </div>
    </div>
   )}
  </div>
 );
};

export default Footer;
