import axios from "axios";
import { getLocalStorage } from "../storage/localStorage";
import { v4 as uuidv4 } from "uuid";

// Code for request interceptor
const axiosRequestInterceptor = async (config) => {
  const token = await getLocalStorage("token");

  // Generate a unique Request ID for each API call
  const requestId = uuidv4();
  console.log("New requestId generated samir ==== ", requestId)

  // Add token and Request ID to the headers if they exist
  config.headers = {
    ...config.headers,
    authorization: token ? `Bearer ${token}` : "",
    RequestID: requestId,
  };

  return config;
};

axios.interceptors.request.use(axiosRequestInterceptor);

// Code for response interceptor
const axiosResponseInterceptor = (res) => {
  return res;
};
axios.interceptors.response.use(axiosResponseInterceptor);

export const apiRequest = axios;
