import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Expertly_Svg from "../../assets/svg/Expertly_Svg";
import Interactive_Svg from "../../assets/svg/Interactive_Svg";
import Landing_Svg from "../../assets/svg/Landing_Svg";
import Personalized_Svg from "../../assets/svg/Personalized_Svg";
import Students_Profile from "../../assets/svg/Students_Profile_Svg";
import Styles from "../css/Landing_Screen.module.css";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import Quotes_Svg from "../../assets/svg/Quotes_Svg";
import Right_Arrow_Svg from "../../assets/svg/Right_Arrow_Svg";
import Left_Arrow_Svg from "../../assets/svg/Left_Arrow_Svg";
import Down_Arrow_Svg from "../../assets/svg/Down_Arrow_Svg";
import { CONFIG, TOP_EMAIL_DOMAINS } from "../../config/Constant";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { tabId, userDetails, setTrendingCourses, setPurchasedCourses } from "../../redux/action";
import { PostMethod,GetMethodPurchasedCourse } from "../../api/request/Api_Calls";
import { API_URL } from "../../api/URL/Api_Route";
import RunTimeConstant from "../../config/RuntimeConfig";
import Loader from "../../component/javascript/loader";
import NetworkError from "../../component/javascript/Network_Error";
import Footer from "../../component/javascript/Footer";
import Header_Component from "../../component/javascript/Header_Component";
import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from "js-cookie";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { A11y, Autoplay, Navigation, Pagination, Scrollbar, Thumbs } from "swiper/modules";
import queryString from "query-string";

const Landing_Screen = () => {
 const default_class_image = require("../../assets/image/Default_class_image.jpg");
 const sample_video = require("../../assets/video/sample_video.mp4");
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const { token, session_details, course, purchased_course_details } = useSelector((state) => state.applicationState);
 const [dot_Index, set_Dot_Index] = useState(0);
 const testimonialScrollViewRef = useRef(null);
 const targetRef = useRef(null);
 const videoRef = useRef(null);
 const [currentSlide, setCurrentSlide] = useState(0);
 const slideWidth = 1100; // Adjust this to your slide width

 console.log("course reduxxxx === ", course)
 console.log("purchased_course_details redux === ", purchased_course_details)

 const ValidationSchema = Yup.object().shape({
  user_name: Yup.string()
   .required("User Name is required")
   .test("notEmpty", "User name cannot be empty", (value) => value.trim() !== "")
   .matches(/^[A-Za-z\s]+$/, "User name must be letters only")
   .min(3, "User name must be at least 3 characters"),
  user_email: Yup.string()
   .required("Email is a required field")
   .test("notEmpty", "Email cannot be empty", (value) => value.trim() !== "")
   .email("Invalid email")
   .test("isValidDomain", "Invalid email", (value) => {
    if (value) {
     const domain = value.split("@")[1];
     return !!TOP_EMAIL_DOMAINS[domain];
    }
    return true;
   }),
 });

 const [is_Expand, set_Is_Expand] = useState(0);
 const [is_Loading, set_Is_Loading] = useState(false);
 const [is_Network_Error, set_Is_Network_Error] = useState(false);

 const [drop_Down_Content, set_Drop_Down_Content] = useState([
  {
   id: 0,
   title: "Student Analytics",
   description:
    "Student Analytics has been calculated with this help of the CognitivED website student calculator ",
  },
  {
   id: 1,
   title: "Personalized course suggestions",
   description:
    "Personalized has been calculated with this help of the CognitivED website student calculator ",
  },
  {
   id: 2,
   title: "Parents Login",
   description:
    "Parents Login has been calculated with this help of the CognitivED website student calculator ",
  },
 ]);

 const [trending_Courses, set_Trending_Courses] = useState([]);

 const [testimonial_Content, set_Testimonial_Content] = useState([]);

 const [is_Small_Screen, set_Is_Small_Screen] = useState(false);

 const handleResize = () => {
  if (window.innerWidth < 992) {
   set_Is_Small_Screen(true);
  } else {
   set_Is_Small_Screen(false);
  }
  // console.log("window.innerWidth", window.innerWidth)

  // Check for landscape orientation with a max-width of 767 pixels
  // const isLandscapeOrientation = window.matchMedia(
  //   "(max-height: 575.98px) and (orientation: landscape)"
  // ).matches;
  // setIsLandscape(isLandscapeOrientation);
 };

 const start_From_Mid = () => {
  // console.log("start_From_Mid", videoRef.current);
  // const startTime = 30; // for example, starting from 30 seconds
  // Update the currentTime property of the video element
  // videoRef.current.currentTime = startTime;
  if (videoRef.current !== null) {
   // Set the desired starting time in seconds
   const startTime = 30; // for example, starting from 30 seconds
   // Update the currentTime property of the video element
   videoRef.current.currentTime = startTime;
   // Play the video
   videoRef.current.play().catch((error) => {
    // Handle play promise rejection due to browser autoplay policy
    console.error("Auto-play failed:", error);
   });
  }
 };

 // ==================== by samir open

 const getPurchasedCourse = async () => {
  set_Is_Loading(true);
  let payload = {
    url: API_URL.get_purchase_course,
    data: {
      UserID: session_details?.sub,
    },
  };

  return GetMethodPurchasedCourse(payload) // Return the promise
    .then(async (response) => {
      console.log("getPurchasedCourse response outside my_courses", response);
      if (response.status === 200) {
        const new_data = response.data.data; // Array of course IDs
        dispatch(setPurchasedCourses(new_data));
        console.log("new_data outside my_courses === ", new_data);
        set_Is_Loading(false);
        return new_data; // Return the purchased course data
      } else {
        set_Is_Loading(false);
        toast.dismiss();
        toast.error(response.message);
        return []; // Return an empty array or handle it as needed
      }
    })
    .catch((error) => {
      console.log("getPurchasedCourse error outside", error);
      set_Is_Loading(false);
      set_Is_Network_Error(true);
      return []; // Return an empty array or handle it as needed
    });
};

/* Get Trending Course API from Course Management Service */
const get_Trending_Courses = async () => {
  console.log("JDAGHJGADHJAGAH CC ======== ", session_details?.cc);
  set_Is_Loading(true);
  let payload = {
    url: API_URL.trendingCourse,
    data: {
      cc: session_details?.cc,
      limit: 4,
    },
  };

  try {
    const response = await PostMethod(payload);
    console.log("get_Trending_Courses response", response.data);

    if (response.success) {
      let exist_data = [];

      // Wait for purchased courses data
      let purchased_course_data = await getPurchasedCourse();
      console.log("purchased_course_data inside trending_course funcctionnn", purchased_course_data);

      const new_data = response.data.filter(trendingCourse => 
        !purchased_course_data.some(purchasedCourse => 
            purchasedCourse.Course.CourseID === trendingCourse._id
        )
    );

    // const new_data = response.data;

    // console.log("Not purchased trending courses data ====== ", new_data)
      
      new_data.forEach((ele) => {
        ele.thumnail_image = `${RunTimeConstant.SERVICEURL.CS}assert/image?id=${encodeURIComponent(ele.AssetPath + ele.Image)}`;
        exist_data.push(ele);
      });

      console.log("complete data for course to pass to next page ==== ", exist_data)
      dispatch(setTrendingCourses(exist_data)); // stored trending couse data into redux

      set_Trending_Courses(exist_data);
      set_Is_Loading(false);
    } else {
      set_Is_Loading(false);
      toast.dismiss();
      toast.error(response.message);
    }
  } catch (error) {
    console.log("get_Trending_Courses error", error);
    set_Is_Loading(false);
    set_Is_Network_Error(true);
  }
};


 // ==================== by samir close

 const get_Testimonial = () => {
  let payload = {
   url: API_URL.readTestimonial,
   data: {},
  };
  // PostMethod(payload)
  //  .then((response) => {
  //   console.log("get_Testimonial response", response.Testimonial);
  //   if (response.success) {
  //    let exist_data = [];
  //    const new_data = response?.Testimonial;
  //    new_data.forEach((ele) => {
  //     ele.thumnail_image = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
  //      ele.AssetPath + ele.Image
  //     )}`;
  //     exist_data.push(ele);
  //    });
  //    set_Testimonial_Content(exist_data);
  //   } else {
  //    toast.dismiss();
  //    toast.error(response.message);
  //   }
  //  })
  //  .catch((error) => {
  //   console.log("get_Testimonial error", error);
  //   set_Is_Network_Error(true);
  //  });
 };

 useEffect(() => {
  dispatch(tabId({ tab_Id: 0 }));
  // start_From_Mid()

  handleResize();
  window.addEventListener("resize", handleResize);
  return () => {
   window.removeEventListener("resize", handleResize);
  };
 }, []);

 const getProfileDetails = () => {
  let payload = {
   url: API_URL.usersAccount,
   data: {
    _id: token,
   },
  };
  PostMethod(payload)
   .then(async (response) => {
    console.log("getProfileDetails response in header", response);
    if (response.success) {
     const new_data = response?.data;
     if (new_data?.Image != "") {
      new_data.profileImage = `${RunTimeConstant.BASEURL}assert/image?id=${encodeURIComponent(
       "PROFILE/" + new_data.Image
      )}`;
     } else {
      const avatar_image = require("../../assets/image/avatar.png");
      new_data.profileImage = avatar_image;
     }
     dispatch(userDetails({ user_details: new_data }));
    }
   })
   .catch((error) => {
    console.log("getProfileDetails error", error);
   });
 };

 useLayoutEffect(() => {
  if (session_details?.cc) {
    get_Trending_Courses();
  }
  if (token) {
   getProfileDetails();
  }
 }, [session_details?.cc]);

 const scrollLeft = () => {
  if (testimonialScrollViewRef.current) {
   testimonialScrollViewRef.current.scrollTo({
    left: testimonialScrollViewRef.current.scrollLeft - 1100,
    behavior: "smooth",
   });
  }
 };

 const scrollRight = () => {
  // set_Scroll_Icon(true)
  if (testimonialScrollViewRef.current) {
   testimonialScrollViewRef.current.scrollTo({
    left: testimonialScrollViewRef.current.scrollLeft + 1100,
    behavior: "smooth",
   });
  }
 };

 const handleScrollTo = () => {
  if (targetRef.current) {
   const topOffset = targetRef.current.offsetTop - 65;
   window.scrollTo({ top: topOffset, behavior: "smooth" });
  }
 };

 const handleDotClick = (index) => {
  setCurrentSlide(index);
  scrollToSlide(index);
 };

 const scrollRightDots = () => {
  if (currentSlide < totalSlides - 1) {
   const newIndex = currentSlide + 1;
   setCurrentSlide(newIndex);
   scrollToSlide(newIndex);
  }
 };

 const scrollLeftDots = () => {
  if (currentSlide > 0) {
   const newIndex = currentSlide - 1;
   setCurrentSlide(newIndex);
   scrollToSlide(newIndex);
  }
 };

 const scrollToSlide = (index) => {
  if (testimonialScrollViewRef.current) {
   testimonialScrollViewRef.current.scrollTo({
    left: index * slideWidth,
    behavior: "smooth",
   });
  }
 };

 const exploreCourse = (selectedCourseID) => {
  if (token != null) {
    const dataString = JSON.stringify(selectedCourseID);
    console.log("course data id ====== ", dataString);
    const queryStringified = queryString.stringify({ data: dataString });
    navigate(`/courseContent?${queryStringified}`);
  } else {
    navigate("/login");
    // toast.dismiss()
    // toast("Login to account")
  }
};

 const see_All_Courses = () => {
  if (token != null) {
   navigate("/allCourse");
  } else {
   navigate("/login");
   // toast.dismiss()
   // toast("Login to account")
  }
 };

 // const handleDotClick = (index) => {
 //   setCurrentSlide(index);
 //   console.log(index);
 // };

 const Section_1 = () => {
  return (
   <>
    <div className={Styles.section_1_Container}>
     <div className={Styles.section_Part_1}>
      <div className={Styles.welcome_Text}>
       Welcome to CognitivED
       <br />
       Empowering <span style={{ color: "var(--primary-color)" }}>Students</span> CognitivED
      </div>

      <div className={Styles.description_Text}>
       Unlock the potential of your child's education with{" "}
       <span className={Styles.font_Weight_700}>CognitivED</span>,{/* <br /> */} the ultimate
       learning companion for{" "}
       <span className={Styles.font_Weight_700}>UAE school students</span>.<br />
       We specialize in <span className={Styles.font_Weight_700}>
        providing engaging
       </span> and <span className={Styles.font_Weight_700}>comprehensive resources</span>.
      </div>

      {is_Small_Screen &&
       (token != null ? (
        <div></div>
       ) : (
        <div className={Styles.section_1_Button_Direction}>
         <div
          className={Styles.button_View}
          tabIndex={0}
          onClick={() => navigate("/login")}
          onKeyDown={(event) => {
           console.log("e", event.key);
           if (event.key == "Enter") {
            navigate("/login");
           }
          }}
         >
          <div className={Styles.login_Text}>Sign in</div>
         </div>
         <div
          className={Styles.button_View}
          style={{
           backgroundColor: "var(--secondary-color)",
           border: "1px solid var(--primary-color)",
          }}
          tabIndex={0}
          onClick={() => navigate("/registration")}
          onKeyDown={(event) => {
           console.log("e", event.key);
           if (event.key == "Enter") {
            navigate("/registration");
           }
          }}
         >
          <div className={Styles.login_Text} style={{ color: "var(--primary-color)" }}>
           Sign up
          </div>
         </div>
        </div>
       ))}

      {!is_Small_Screen && (
       <div className={Styles.button_Alignment_View}>
        <div>
         <div
          className={Styles.button_View}
          tabIndex={0}
          onKeyDown={(event) => {
           console.log("e", event.key);
           if (event.key == "Enter") {
            // props.onClicking()
            see_All_Courses();
           }
          }}
          onClick={() => see_All_Courses()}
         >
          <div className={Styles.button_Text}>
           {/* {token != null ?  */}
           Explore Our Course
           {/* : "Start learning for free"
                                        } */}
          </div>
         </div>
        </div>

        <div style={{ marginLeft: "14px" }}>
         <div>
          <Students_Profile />
         </div>
         <div className={Styles.like_Text}>Join 1k+ students like You</div>
        </div>
       </div>
      )}
     </div>
     <div className={Styles.section_Part_2}>
      <Landing_Svg
       width={is_Small_Screen ? "347" : "464"}
       height={is_Small_Screen ? "216" : "290"}
      />
     </div>
     {/* {is_Small_Screen && (
                    <div className={Styles.section_1_App_Logo_View}>
                        <div>
                            <App_Logo/>
                        </div>
                        <div className={Styles.section_1_App_Name_Text}>e-Learning</div>
                    </div>
                )} */}
    </div>
   </>
  );
 };

 const Section_2 = () => {
  return (
   <div className={Styles.section_2_Container}>
    <div className={Styles.section_2_Title}>Why Choose CognitivED?</div>

    <div className={Styles.card_Container}>
     <div className={Styles.card_View}>
      <div className={Styles.card_Title}>Personalized Course Suggestions</div>
      <div>
       <Personalized_Svg />
      </div>
      <div className={Styles.card_description}>
       Every student is unique. That's why{" "}
       <span className={Styles.font_Weight_700}>CognitivED </span>
       provides personalized learning paths, catering to individual strengths and areas that{" "}
       <span className={Styles.font_Weight_700}>needs improvement.</span>
      </div>
     </div>

     <div className={Styles.card_View}>
      <div className={Styles.card_Title}>Expertly Crafted Content</div>
      <div>
       <Expertly_Svg />
      </div>
      <div className={Styles.card_description}>
       Our team of experienced educators has curated a{" "}
       <span className={Styles.font_Weight_700}>rich library of content</span>, covering every
       topic in Math, Physics, and Chemistry, to help students
       <span className={Styles.font_Weight_700}> grasp complex concepts with ease</span>.
      </div>
     </div>

     <div className={Styles.card_View}>
      <div className={Styles.card_Title}>Interactive Learning</div>
      <div>
       <Interactive_Svg />
      </div>
      <div className={Styles.card_description}>
       Say goodbye to traditional rote learning.
       <span className={Styles.font_Weight_700}> CognitivED</span> offers interactive lessons,
       quizzes, and challenges to make learning enjoyable and effective.
      </div>
     </div>
    </div>
   </div>
  );
 };

 const Section_3 = () => {
  // return (
  //   <>
  //   <div className={Styles.section_3_Container}>
  //     <div className={Styles.section_2_Title}>Checkout Our Key Features</div>
  //     <div className={Styles.section_3_View}>
  //       <div className={Styles.drop_Down_Direction}>
  //         {drop_Down_Content.map((ele, ind) => {
  //           return (
  //             <div
  //               key={ind}
  //               className={
  //                 is_Expand == ele?.id
  //                   ? Styles.drop_Down_View
  //                   : Styles.drop_Down_View_1
  //               }
  //             >
  //               <div
  //                 onClick={() => set_Is_Expand(ele?.id)}
  //                 className={Styles.drop_Down_Title_View}
  //               >
  //                 <div
  //                   className={Styles.drop_Down_Title}
  //                   style={{
  //                     color:
  //                       is_Expand == ele?.id ? "#FFF" : "var(--text-color)",
  //                   }}
  //                 >
  //                   {ele?.title}
  //                 </div>
  //                 <div style={{ paddingRight: "21px" }}>
  //                   {is_Expand == ele?.id ? (
  //                     // <CiCircleChevUp color={ "#FFF" } size={22}/>
  //                     <></>
  //                   ) : (
  //                     <Down_Arrow_Svg />
  //                     // <CiCircleChevDown color={'var(--primary-color)'} size={22}/>
  //                   )}
  //                 </div>
  //               </div>
  //               {is_Expand == ele?.id && (
  //                 <>
  //                   <div className={Styles.drop_Down_Description}>
  //                     {ele?.description}
  //                   </div>
  //                   {is_Small_Screen && (
  //                     <div className={Styles.video_Player_Container}>
  //                       {/* <Video_Player_Svg /> */}
  //                       <video
  //                         src={sample_video}
  //                         crossOrigin="anonymous"
  //                         style={{
  //                           width: "100%",
  //                           height: "100%",
  //                           objectFit: "fill",
  //                         }}
  //                         type="video/mp4"
  //                         controls
  //                       />
  //                     </div>
  //                   )}
  //                 </>
  //               )}
  //             </div>
  //           );
  //         })}
  //       </div>
  //       {/* <video ref={videoRef} controls>
  //                         <source src="your_video_source_here.mp4" type="video/mp4" />
  //                         Your browser does not support the video tag.
  //                         </video> */}
  //       {!is_Small_Screen && (
  //         <div
  //           className={Styles.video_Player_Container}
  //           onClick={start_From_Mid()}
  //         >
  //           <video
  //             ref={videoRef}
  //             style={{ width: "100%", height: "100%", objectFit: "fill" }}
  //             controls
  //           >
  //             <source
  //               src={sample_video}
  //               crossOrigin="anonymous"
  //               type="video/mp4"
  //             />
  //           </video>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  //   </>
  // );
 };

 const Section_4 = () => {
  return (
   <div className={Styles.section_4_Container} ref={targetRef}>
    <div className={Styles.section_2_Title}>Our Courses</div>

    <div className={Styles.section_4_View}>
     {/* <div className={Styles.section_4_Title}>
                        All Class <span style={{fontWeight:'700', color:'var(--primary-color)', textDecoration:'underLine'}}>“1 - 12 Class (NCERT)”</span>
                    </div> */}
     <div className={Styles.course_Card_Direction}>
      {trending_Courses.map((ele, ind) => {
       return (
        <div key={ind} className={Styles.course_Card}>
         <div className={Styles.course_Image_View}>
          <img
           src={ele.thumnail_image}
           crossOrigin="anonymous"
           className={Styles.image_Cover}
          />
         </div>

         <div className={Styles.course_Title_View}>
          <div className={Styles.course_Title}>{ele?.CourseName}</div>

{/*           <div
           className={Styles.course_Button_View}
           tabIndex={0}
           onClick={() => exploreCourse(ele?._id)}
           onKeyDown={(event) => {
            console.log("e", event.key);
            if (event.key == "Enter") {
             // props.onClicking()
            }
           }}
          >
           Explore
          </div> */}

<div
  className={Styles.course_Button_View}
  tabIndex={0}
  onClick={() => exploreCourse(ele?._id)}
  onKeyDown={(event) => {
    if (event.key === "Enter") {
      exploreCourse(ele?._id);
    }
  }}
>
  Explore
</div>

         </div>
        </div>
       );
      })}
     </div>

     <div className={Styles.see_All_Course_Text_View}>
      <div onClick={() => see_All_Courses()} className={Styles.see_All_Course_Body}>
       <div className={Styles.see_All_Course_Text}>See all Courses</div>
       <div>
        <BiChevronRight size={25} color={"var(--primary-color)"} />
       </div>
      </div>
     </div>
    </div>
   </div>
  );
 };

 // const scrollToIndex = (index) => {
 //     const scrollToX = index * 400;
 //     testimonialScrollViewRef.current.scrollTo({ left: scrollToX, behavior: 'smooth' });
 //     };

 // const handleScroll_Func =() =>{
 //     let newValue={...testimonialScrollViewRef}
 //     console.log(testimonial_Content.length,newValue.current.clientWidth)
 //     console.error("handleScroll",newValue.current.scrollLeft  / (newValue.current.scrollWidth - newValue.current.clientWidth))
 //      let scrollPercentage= newValue.current.scrollLeft  / (newValue.current.scrollWidth - newValue.current.clientWidth)
 //     let data= Math.floor(scrollPercentage * 4);
 //     console.error("current index",data)
 //     set_Dot_Index(data)
 // }
 const totalSlides = Math.ceil(testimonial_Content.length / 2);

 const Section_5 = () => {
  return (
   <div className={Styles.section_5_Container}>
    <div className={Styles.section_2_Title}>
     {testimonial_Content.length !== 0 && "Testimonials"}
    </div>
    <div className={Styles.section_5_Direction}>
     <Swiper
      className={Styles.testimonial_Scroll_View}
      ref={testimonialScrollViewRef}
      slidesPerView={is_Small_Screen ? 1 : 2}
      spaceBetween={is_Small_Screen ? 130 : 30}
      scrollbar={{ draggable: true }}
      pagination={{
       clickable: true,
       dynamicBullets: false,
      }}
      modules={[Pagination]}
      style={{ width: "95%" }}
     >
      {testimonial_Content.map((ele, index) => (
       <SwiperSlide key={index}>
        <div className={Styles.testimonial_Content_View}>
         <div>
          <div className={Styles.name_Text}>{ele?.Name}</div>
          <span className={Styles.indication_text}>
           {ele?.UserType ? "Parent" : "Student"}
          </span>
          <div className={Styles.testimonial_Content_Card}>
           <div className={Styles.testimonial_Content}>
            {`"${ele?.Content}"`}
            <div style={{ width: "100%" }}>
             <div className={Styles.quotes_View}>
              <Quotes_Svg />
             </div>
            </div>
           </div>
          </div>
         </div>
         <div className={Styles.testimonial_Image_View}>
          <img
           src={ele?.thumnail_image}
           className={Styles.image_Cover}
           crossOrigin="anonymous"
           alt={`Thumbnail ${index}`}
          />
         </div>
        </div>
       </SwiperSlide>
      ))}
     </Swiper>
    </div>

    {/* <div className={Styles.section_5_Direction}>
          <div>
            {!is_Small_Screen && (
              <div
                onClick={() => scrollLeft()}
                className={Styles.scroll_Icon}
                tabIndex={0}
                onKeyDown={(event) => {
                  console.log("e", event.key);
                  if (event.key == "Enter") {
                    scrollLeft();
                  }
                }}
              >
                <Left_Arrow_Svg />
              </div>
            )}
          </div>

          <div
            className={Styles.testimonial_Scroll_View}
            ref={testimonialScrollViewRef}
          >
            {testimonial_Content.map((ele, index) => {
              return (
                <div className={Styles.testimonial_Content_View} key={index}>
                  <div key={index}>
                    <div className={Styles.name_Text}>{ele?.Name}</div>
                    <span className={Styles.indication_text}>
                      {ele?.UserType ? "Parent" : "Student"}
                    </span>

                    <div className={Styles.testimonial_Content_Card}>
                      <div
                        style={{
                          width: "100%",
                          flexWrap: "wrap",
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <div className={Styles.testimonial_Content}>
                          {`"${ele?.Content}"`}
                          <div style={{ width: "100%" }}>
                            <div className={Styles.quotes_View}>
                              <Quotes_Svg />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={Styles.testimonial_Image_View}>
                    <img
                      src={ele?.thumnail_image}
                      className={Styles.image_Cover}
                      crossOrigin="anonymous"
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            {!is_Small_Screen && (
              <div
                onClick={() => scrollRight()}
                className={Styles.scroll_Icon}
                tabIndex={0}
                onKeyDown={(event) => {
                  console.log("e", event.key);
                  if (event.key == "Enter") {
                    scrollRight();
                  }
                }}
              >
                <Right_Arrow_Svg />
              </div>
            )}
          </div>
        </div> */}
    {/* <div
          style={{
            paddingTop: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
         {Array.from({ length: totalSlides }).map((_, index) => (
            <div
              key={index}
              style={{
                borderRadius: "50%",
                height: "8px",
                width: "8px",
                backgroundColor: index === currentSlide ? "#7445ff" : "#D9D9D9",
                cursor: "pointer",
              }}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div> */}

    {/* <div className={Styles.section_5_Direction}>
          <Swiper
            spaceBetween={30}
            slidesPerView={is_Small_Screen ? 1 : 2}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            id={Styles.mySwiper}
            className={Styles.testimonial_Scroll_View}
            // ref={testimonialScrollViewRef}
            // onScroll={(e)=> handleScroll_Func(e)}
          >
            {testimonial_Content.map((ele, index) => {
              // console.log("test index", index)
              return (
                <SwiperSlide
                  className={Styles.testimonial_Content_View}
                  key={index}
                >
                  <div
                    key={index}
                    className={Styles.testimonialCont}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      flexDirection: "column",
                      paddingInline: "40px",
                    }}
                  >
                    <div className={Styles.name_Text}>{ele?.Name}</div>
                    <span className={Styles.indication_text}>
                      {ele?.UserType ? "Parent" : "Student"}
                    </span>

                    <div className={Styles.testimonial_Content_Card}>
                      <div
                        style={{
                          width: "100%",
                          flexWrap: "wrap",
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <div
                            className={Styles.testimonial_Content}
                            style={{ marginBottom: "-5px" }}
                          >
                            {`"${ele?.Content}"`}
                          </div>
                          <div style={{ width: "100%" }}>
                            <div className={Styles.quotes_View}>
                              <Quotes_Svg />
                            </div>
                          </div>
                        </div>
                        <div
                          className={Styles.testimonial_Image_View}
                          style={{ height: "100%" }}
                        >
                          <img
                            src={ele?.thumnail_image}
                            className={Styles.image_Cover}
                            crossOrigin="anonymous"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div></div>
        </div> */}
    {/* {is_Small_Screen && (
          <div className={Styles.section_5_Dot_View}>
            {testimonial_Content.map((ele, index) => (
              <div
                key={index}
                // onClick={() => scrollToIndex(index)}
                // onClick={async() => {
                //     await set_Dot_Index(index)
                //     await scrollToIndex(index)
                // }}
                className={Styles.section_5_Dot_Style}
                style={{
                  backgroundColor:
                    dot_Index == ele?.id
                      ? "var(--primary-color)"
                      : "var(--secondary-color)",
                }}
              ></div>
            ))}
          </div>
        )} */}
   </div>
  );
 };

 const onSubmit = (value) => {
  set_Is_Loading(true);
  let payload = {
   url: API_URL.letterPad,
   data: {
    UserName: value.user_name,
    EmailId: value.user_email,
   },
  };
  PostMethod(payload)
   .then((response) => {
    console.log("News letter response", response);
    if (response.success) {
     set_Is_Loading(false);
     toast.dismiss();
     toast.success("News letter updated successfully");
    } else {
     set_Is_Loading(false);
     toast.dismiss();
     toast.error(response.message);
    }
   })
   .catch((error) => {
    set_Is_Loading(false);
    set_Is_Network_Error(true);
   });
 };

 const Section_6 = () => {
  return (
   <div className={Styles.section_6_Container}>
    <div className={Styles.section_6_Direction}>
     <div className={Styles.section_6_Part_1}>
      <div className={Styles.section_6_Title}>
       Get an exclusive
       <br />
       <span style={{ color: "var(--primary-color)" }}>offer through </span>
       email
      </div>
      {!is_Small_Screen && (
       <div>
        <Landing_Svg
         width={is_Small_Screen ? "262" : "318"}
         height={is_Small_Screen ? "164" : "199"}
        />
       </div>
      )}
     </div>

     <div className={Styles.section_6_Part_2}>
      <div className={Styles.section_6_Form_View}>
       {!is_Small_Screen && <div className={Styles.form_Title}>Get Offer Coupon</div>}
       <Formik
        initialValues={{
         user_name: "",
         user_email: "",
        }}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
       >
        {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         setFieldValue,
        }) => (
         <>
          <div>
           <label className={Styles.field}>Name</label>
           <span style={{ color: "red" }}>*</span>
           <br />
           <input
            type="text"
            name="user_name"
            placeholder="Enter your User Name"
            className={Styles.fieldInput}
            onChange={(val) => {
             setFieldValue("user_name", val.target.value);
            }}
            maxLength={CONFIG.USER_NAME_MAX_LENGTH}
           />
           <div className={Styles.formikErrorText}>
            {errors.user_name && touched.user_name && errors.user_name}
           </div>
          </div>

          <div className={Styles.text_field_Gap}>
           <label className={Styles.field}>Email</label>
           <span style={{ color: "red" }}>*</span>
           <br />
           <input
            type="text"
            name="user_email"
            placeholder="Enter your User mail"
            className={Styles.fieldInput}
            onChange={(val) => {
             setFieldValue("user_email", val.target.value);
            }}
            maxLength={CONFIG.USER_EMAIL_MAX_LENGTH}
           />
           <div className={Styles.formikErrorText}>
            {errors.user_email && touched.user_email && errors.user_email}
           </div>
          </div>

          <div
           className={Styles.form_Button_View}
           tabIndex={0}
           onClick={() => handleSubmit()}
           onKeyDown={(event) => {
            console.log("e", event.key);
            if (event.key == "Enter") {
             handleSubmit();
            }
           }}
          >
           <div className={Styles.form_Button_Text}>Submit</div>
          </div>
         </>
        )}
       </Formik>
      </div>
     </div>
    </div>
   </div>
  );
 };

 // const [isAlert, set_Is_Alert] = useState(true)
 // if(isAlert){
 //     return (
 //         <AlertComponent/>
 //     )
 // }

 if (is_Loading) {
  return <Loader isLoading={is_Loading} callBack={() => set_Is_Loading(false)} />;
 }

 if (is_Network_Error) {
  return <NetworkError callBack={() => set_Is_Network_Error(false)} />;
 }

 return (
  <div className={Styles.section_Container}>
   {/* {token == null && (
    <Header_Component element={"Show"} />
   )} */}
   <Section_1 />
   <Section_2 />
   <Section_3 />
   {trending_Courses.length ? <Section_4 /> : <></>}
   <Section_5 />
   <Section_6 />
   {/* {token == null && (
                <Footer />
            )} */}
  </div>
 );
};

export default Landing_Screen;
