
import React from 'react';
import Styles from "../css/PayLoader.module.css"; 

const PayLoader = () => {
  return (
    <div className={Styles.loader}>
      <div className={Styles.circle} ></div>
    </div>
  );
};

export default PayLoader;
