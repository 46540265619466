import * as Yup from "yup";
import { TOP_EMAIL_DOMAINS } from "../../config/Constant";
// USER_EMAIL_MAX_LENGTH: 50,
// USER_NAME_MAX_LENGTH: 20,
// PASSWORD_MAX_LENGTH: 20,
// PAGINATION_COURSE_LIMIT: 5,

export const initialValues = {
 user_email: "",
 user_name: "",
 password: "",
 confirm_password: "",
 term_and_condition: false,
};

export const data = [
 {
  type: "text",
  placeholder: "Enter your email",
  name: "user_email",
  label: "Email",
  errors: "user_email",
  MAX_LENGTH: 50,
 },
 {
  type: "text",
  placeholder: "Enter your User Name",
  name: "user_name",
  label: "Name",
  errors: "user_name",
  MAX_LENGTH: 20,
 },
 // {type:"text",placeholder:"Enter your Password",name:"password",label:"Password",errors:"password",MAX_LENGTH:20,style:"passwordBox"},
 // {type:"text",placeholder:"Retype your Password",name:"confirm_password",label:"Confirm Password",errors:"confirm_password",MAX_LENGTH:20,style:"passwordBox"},
];

export const passworddata = [
 {
  type: "text",
  placeholder: "Enter your Password",
  name: "password",
  label: "Password",
  errors: "password",
  MAX_LENGTH: 20,
  passwordIconType: "password",
 },
 {
  type: "text",
  placeholder: "Retype your Password",
  name: "confirm_password",
  label: "Confirm Password",
  errors: "confirm_password",
  MAX_LENGTH: 20,
  passwordIconType: "password",
 },
];

export const ValidationSchema = Yup.object().shape({
 user_email: Yup.string()
  .required("Mail ID cannot be empty")
  .test("notEmpty", "Email cannot be empty", (value) => value.trim() !== "")
  .email("Mail ID not found")
  .test("isValidDomain", "Mail ID not found", (value) => {
   if (value) {
    const domain = value.split("@")[1];
    return !!TOP_EMAIL_DOMAINS[domain];
   }
   return true;
  }),
 user_name: Yup.string()
  .required("User Name is a required field")
  .test("notEmpty", "User name cannot be empty", (value) => value.trim() !== "")
  .matches(/^[A-Za-z\s]+$/, "User name must be letters only")
  .min(3, "User name must be at least 3 characters long"),
 password: Yup.string()
  .required("Password is a required field")
  .matches(/\w*[a-z]\w*/, "Password must have a small letter")
  .matches(/\w*[A-Z]\w*/, "Password must have a capital letter")
  .matches(/\d/, "Password must have a number")
  .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, "Password must have a special character")
  .min(8, ({ min }) => `Password must be at least 8 characters`)
  .test("noSpaces", "Please enter valid password", (value) => {
   return !/\s/.test(value);
  }),
 confirm_password: Yup.string()
  .required("Confirm Password is a required field")
  .oneOf([Yup.ref("password")], "Passwords do not match"),
 term_and_condition: Yup.boolean().isTrue("Please accept terms and conditions"),
});
