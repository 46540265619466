import RunTimeConstant from "../../config/RuntimeConfig";
import { apiRequest } from "../Interceptor";
import { API_HEADER, API_TIMEOUT } from "./Config";

/**
 *
 * @param {*} payload payload sent to the server
 */
export const PostMethod = async (payload) => {
 console.log("payload samirr", payload);
 // here we can add manual timeout(promise.race()) to clear the api call
 console.log("RunTimeConstant.CS", RunTimeConstant.SERVICEURL.CS);
 console.log("Full API URL: ", `${RunTimeConstant.SERVICEURL.CS}${payload.url}`);
 console.log("payload.data === : ", `${payload.data}`);

 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "post",
   url: payload.url,
   baseURL: RunTimeConstant.SERVICEURL.CS,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
   withCredentials: true,
  })
   .then((res) => {
    console.log("Res", res);
    resolve(res.data); //status and data if(200){sucess} else{condition}
   })
   .catch((err) => {
    console.log("err", err);
    reject(err);
   });
 });
 return promise;
};

export const newPostMethod = async (payload) => {
 console.log("payload =====qwqwq", payload, RunTimeConstant.SERVICEURL.AMS);
 // here we can add manual timeout(promise.race()) to clear the api call
 // console.log("RunTimeConstant.BASEURL", RunTimeConstant.BASEURL, process.env.NODE_ENV);
 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "post",
   url: payload.url,
   baseURL: RunTimeConstant.SERVICEURL.AMS,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
   withCredentials: true,
  })
   .then((res) => {
    console.log("response ==== ", res.data);
    resolve(res); //status and data if(200){sucess} else{condition}
   })
   .catch((err) => {
    console.log("err", err);
    reject(err);
   });
 });
 return promise;
};

export const newLoginPostMethod = async (payload) => {
 console.log("payload", payload, RunTimeConstant.SERVICEURL.IMS);
 // here we can add manual timeout(promise.race()) to clear the api call
 // console.log("RunTimeConstant.BASEURL", RunTimeConstant.BASEURL, process.env.NODE_ENV);
 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "post",
   url: payload.url,
   baseURL: RunTimeConstant.SERVICEURL.IMS,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
   withCredentials: true,
  })
   .then((res) => {
    console.log("rest obj", res);
    console.log("rest data", res.data);
    resolve(res); //status and data if(200){sucess} else{condition}
   })
   .catch((err) => {
    console.log("err hello", err);
    reject(err);
   });
 });
 return promise;
};

export const newLoginGetMethod = async (payload) => {
 console.log("payload", payload, RunTimeConstant.SERVICEURL.IMS);
 // here we can add manual timeout(promise.race()) to clear the api call
 // console.log("RunTimeConstant.BASEURL", RunTimeConstant.BASEURL, process.env.NODE_ENV);
 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "get",
   url: payload.url,
   baseURL: RunTimeConstant.SERVICEURL.IMS,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
   withCredentials: true,
  })
   .then((res) => {
    resolve(res);
   })
   .catch((err) => {
    console.log("err", err);
    reject(err);
   });
 });
 return promise;
};

export const getSessionMethod = async (payload) => {
 //to get the session
 console.log("payload", payload, RunTimeConstant.SERVICEURL.CMS, API_HEADER);

 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "get",
   url: payload.url,
   baseURL: RunTimeConstant.SERVICEURL.CMS,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
   withCredentials: true,
  })
   .then((res) => {
    resolve(res);
   })
   .catch((err) => {
    console.log("err", err);
    reject(err);
   });
 });
 return promise;
};

export const deleteExistingSession = async (payload) => {
 //to get the session
 console.log("payload", payload);

 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "delete",
   url: payload.url,
   baseURL: RunTimeConstant.SERVICEURL.CMS,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
   withCredentials: true,
  })
   .then((res) => {
    resolve(res);
   })
   .catch((err) => {
    console.log("err", err);
    reject(err);
   });
 });
 return promise;
};

export const NewPostMethod = async (payload) => {
 console.log("payload =====>", payload);
 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "post",
   url: payload.url,
   baseURL: payload.baseURL,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
   withCredentials: payload.withCredentials,
  })
   .then((res) => {
    console.log("res", res);
    resolve(res); //status and data if(200){sucess} else{condition}
   })
   .catch((err) => {
    console.error("err", err);
    reject(err);
   });
 });
 return promise;
};

/* cart Add apis */
export const PostMethodCart = async (payload) => {
 console.log("payload req add cart === ", payload);
 // here we can add manual timeout(promise.race()) to clear the api call
 console.log("RunTimeConstant.CS", RunTimeConstant.SERVICEURL.PRSMS);
 console.log("Full API URL: ", `${RunTimeConstant.SERVICEURL.PRSMS}${payload.url}`);
 console.log("payload.data === : ", `${payload.data}`);

 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "post",
   url: payload.url,
   baseURL: RunTimeConstant.SERVICEURL.PRSMS,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
   withCredentials: true,
  })
   .then((res) => {
    console.log("add cart api response ====", res);
    resolve(res); //status and data if(200){sucess} else{condition}
   })
   .catch((err) => {
    console.log("err", err);
    reject(err);
   });
 });
 return promise;
};

/* cart Add apis */
export const DeleteMethodCart = async (payload) => {
 console.log("payload req delete cart === ", payload);
 // here we can add manual timeout(promise.race()) to clear the api call
 console.log("RunTimeConstant.CS", RunTimeConstant.SERVICEURL.PRSMS);
 console.log("Full API URL: ", `${RunTimeConstant.SERVICEURL.PRSMS}${payload.url}`);
 console.log("payload.data === : ", `${payload.data}`);

 let promise = new Promise(async (resolve, reject) => {
  await apiRequest({
   method: "delete",
   url: payload.url,
   baseURL: RunTimeConstant.SERVICEURL.PRSMS,
   timeout: API_TIMEOUT,
   headers: payload.header ? payload.header : API_HEADER,
   data: payload.data,
   withCredentials: true,
  })
   .then((res) => {
    console.log("delete cart api response ====", res);
    resolve(res); //status and data if(200){sucess} else{condition}
   })
   .catch((err) => {
    console.log("err", err);
    reject(err);
   });
 });
 return promise;
};

/* get purchased course apis */
export const GetMethodPurchasedCourse = async (payload) => {
  console.log("payload req my_courses === ", payload);
  // here we can add manual timeout(promise.race()) to clear the api call
  console.log("RunTimeConstant.CS", RunTimeConstant.SERVICEURL.PRSMS);
  console.log("Full API URL: ", `${RunTimeConstant.SERVICEURL.PRSMS}${payload.url}`);
  console.log("payload.data === : ", payload.data);
  console.log("vdedkjnedkd=======",payload.url + `?UserID=${payload.data.UserID}`);
 
  let promise = new Promise(async (resolve, reject) => {
   await apiRequest({
    method: "get",
    url: payload.url + `?UserID=${payload.data.UserID}`,
    baseURL: RunTimeConstant.SERVICEURL.PRSMS,
    timeout: API_TIMEOUT,
    headers: payload.header ? payload.header : API_HEADER,
    // data: payload.data,
    withCredentials: true,
   })
    .then((res) => {
     console.log("get my courses api response ====", res);
     resolve(res); //status and data if(200){sucess} else{condition}
    })
    .catch((err) => {
     console.log("err", err);
     reject(err);
    });
  });
  return promise;
 };

/* get cart course apis */
export const GetMethodCartCourse = async (payload) => {
  console.log("payload req get cart === ", payload);
  // here we can add manual timeout(promise.race()) to clear the api call
  console.log("RunTimeConstant.CS", RunTimeConstant.SERVICEURL.PRSMS);
  console.log("Full API URL: ", `${RunTimeConstant.SERVICEURL.PRSMS}${payload.url}`);
  console.log("payload.data === : ", payload.data);
  console.log("vdedkjnedkd=======",payload.url + `?UserID=${payload.data.UserID}`);
 
  let promise = new Promise(async (resolve, reject) => {
   await apiRequest({
    method: "get",
    url: payload.url + `?UserID=${payload.data.UserID}`,
    baseURL: RunTimeConstant.SERVICEURL.PRSMS,
    timeout: API_TIMEOUT,
    headers: payload.header ? payload.header : API_HEADER,
    // data: payload.data,
    withCredentials: true,
   })
    .then((res) => {
     console.log("get my cart data ====", res);
     resolve(res); //status and data if(200){sucess} else{condition}
    })
    .catch((err) => {
     console.log("err", err);
     reject(err);
    });
  });
  return promise;
 };

//  export const newGetMethod = async (payload) => {
//   console.log("payload", payload);
//   // here we can add manual timeout(promise.race()) to clear the api call
//   // console.log("RunTimeConstant.BASEURL", RunTimeConstant.BASEURL, process.env.NODE_ENV);
//   let promise = new Promise(async (resolve, reject) => {
//    await apiRequest({
//     method: "get",
//     url: payload.url,
//     baseURL: RunTimeConstant.BASEURL,
//     timeout: API_TIMEOUT,
//     headers: payload.header ? payload.header : API_HEADER,
//     data: payload.data,
//    })
//     .then((res) => {
//      // console.log('resttttttttttttttt', res );
//      resolve(res.data); //status and data if(200){sucess} else{condition}
//     })
//     .catch((err) => {
//      console.log("err", err);
//      reject(err);
//     });
//   });
//   return promise;
//  };


export const PostMethodTax = async (payload) => {
  console.log("PostMethodTax payload === ", payload);
  // here we can add manual timeout(promise.race()) to clear the api call
  console.log("RunTimeConstant.CS", RunTimeConstant.SERVICEURL.TMS);
  console.log("Full API URL: ", `${RunTimeConstant.SERVICEURL.TMS}${payload.url}`);
  console.log("payload.data === : ", `${payload.data}`);
 
  let promise = new Promise(async (resolve, reject) => {
   await apiRequest({
    method: "post",
    url: payload.url,
    baseURL: RunTimeConstant.SERVICEURL.TMS,
    timeout: API_TIMEOUT,
    headers: payload.header ? payload.header : API_HEADER,
    data: payload.data,
    withCredentials: true,
   })
    .then((res) => {
     console.log("Res", res);
     resolve(res.data); //status and data if(200){sucess} else{condition}
    })
    .catch((err) => {
     console.log("err", err);
     reject(err);
    });
  });
  return promise;
 };